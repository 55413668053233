import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Provider, TitleBar } from '@shopify/app-bridge-react';
import axios from 'axios';
import io from 'socket.io-client';
import queryString from 'query-string';
import { Page, Stack, Spinner, DisplayText, TextStyle } from '@shopify/polaris';

import { injectIntl } from 'react-intl';

import * as JobStatusStored from '../JobStatus';

import Jobs from '../Jobs';
import { MainContext } from '../Provider';

import translate from '../../i18n/translate';

function AllJobs(props) {
  const history = useHistory();
  const location = useLocation();
  const { jobStatus, isToday } = queryString.parse(location.search);

  const containerRef = React.useRef();
  const mainContext = useContext(MainContext);
  const [pageLoading, setPageLoading] = useState(true);
  const [scrollDataFetching, setScrollDataFetching] = useState(false);
  let skipNum = 10;

  const fetchData = (skipNumLocal, isConcat = true) => {
    axios
      .post(`/get_all_jobs?limit=${skipNum}&skipNum=${skipNumLocal}`, {
        shopName: props.config.shopOrigin,
        jobStatus,
        isToday,
      })
      .then(({ data }) => {
        setPageLoading(false);
        setScrollDataFetching(false);
        if (isConcat) {
          mainContext.setAllJobsPageJobsWithConcat(data);
        } else {
          mainContext.setAllJobsPageJobs(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const scrollHandler = () => {
    if (!scrollDataFetching) {
      const scrollAbleHeight =
        containerRef.current.scrollHeight - window.innerHeight;

      const beforeBottom = 300;

      if (
        scrollAbleHeight < window.scrollY + beforeBottom &&
        scrollAbleHeight - window.scrollY > 80
      ) {
        setScrollDataFetching(true);
        fetchData(skipNum, true);
        skipNum += 10;
      }
    }
  };

  useEffect(() => {
    const socket = io();
    window.scroll(0, 0);
    fetchData(0, false);

    socket.on(props.config.shopOrigin, (msg) => {
      if (msg.exportJobDone) {
        fetchData(0, false);
      }
    });
    window.addEventListener('scroll', scrollHandler);
    return () => {
      mainContext.setAllJobsPageJobs([]);
      socket.off(props.config.shopOrigin);
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  const secondaryActions = [
    {
      content: props.intl.formatMessage({
        id: 'back',
      }),
      onAction: () => history.goBack(),
    },
  ];

  return (
    <MainContext.Consumer>
      {(context) => (
        <div ref={containerRef}>
          <Page
            title={`${
              isToday === 'true'
                ? props.intl.formatMessage({
                    id: 'todays',
                  })
                : ''
            } ${
              jobStatus === JobStatusStored.FINISHED
                ? props.intl.formatMessage({
                    id: 'completed',
                  })
                : jobStatus === JobStatusStored.SCHEDULED
                ? props.intl.formatMessage({
                    id: 'scheduled',
                  })
                : jobStatus === JobStatusStored.FAILED
                ? props.intl.formatMessage({
                    id: 'error',
                  })
                : props.intl.formatMessage({
                    id: 'all',
                  })
            } ${props.intl.formatMessage({
              id: 'jobs',
            })}`}
          >
            <Provider config={props.config}>
              <TitleBar
                title={props.intl.formatMessage({
                  id: 'jobs',
                })}
                secondaryActions={secondaryActions}
              />
            </Provider>
            {context.allJobsPageJobs.length === 0 && pageLoading ? (
              <>
                <br />
                <Stack distribution="center" vertical={true} alignment="center">
                  <br />
                  <br />
                  <Spinner
                    accessibilityLabel="Spinner example"
                    size="large"
                    color="teal"
                  />
                </Stack>
              </>
            ) : (
              <>
                {context.allJobsPageJobs.length === 0 ? (
                  <Stack
                    distribution="center"
                    vertical={true}
                    alignment="center"
                  >
                    <br />
                    <br />
                    <img
                      style={{ width: 250 }}
                      src="/assets/black-tache.png"
                      alt="Icon"
                    />

                    <TextStyle variation="subdued">
                      <DisplayText size="medium">
                        {translate('nothingToSeeHere')}
                      </DisplayText>
                    </TextStyle>
                  </Stack>
                ) : (
                  <Jobs data={context.allJobsPageJobs} />
                )}
              </>
            )}
            {!pageLoading && scrollDataFetching && (
              <>
                <br />
                <Stack distribution="center">
                  <Spinner
                    accessibilityLabel="Spinner example"
                    size="large"
                    color="teal"
                  />
                </Stack>
              </>
            )}
            <br />
            <br />
          </Page>
        </div>
      )}
    </MainContext.Consumer>
  );
}

export default injectIntl(AllJobs);
