import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Provider, TitleBar } from '@shopify/app-bridge-react';
import queryString from 'query-string';
import io from 'socket.io-client';
import moment from 'moment-timezone';

import {
  ProductsMajorMonotone,
  CollectionsMajorMonotone,
  CustomersMajorMonotone,
  DiscountsMajorMonotone,
  DraftOrdersMajorMonotone,
  OrdersMajorMonotone,
  CashDollarMajorMonotone,
  StoreMajorMonotone,
  ViewMajorMonotone,
  TransferMajorMonotone,
  ThemeEditMajorMonotone,
  PageMajorMonotone,
} from '@shopify/polaris-icons';
import {
  Frame,
  Page,
  Card,
  PageActions,
  ChoiceList,
  Icon,
  Badge,
  DisplayText,
  Stack,
  Banner,
  ButtonGroup,
  Button,
  Toast,
  Spinner,
  ProgressBar,
} from '@shopify/polaris';
import axios from 'axios';
import capitalize from 'capitalize';

import { injectIntl } from 'react-intl';

import * as JobStatus from '../JobStatus';
import { MainContext } from '../Provider';

// import components
import ExportBottomOptions from '../ExportBottomOptions';
import JobTopStatusBar from '../JobTopStatusBar';

import ExportProgressBadge from '../ExportProgressBadge';

import secondsToHms from '../../util/secondsToHms';

const SingleExportJob = (props) => {
  const { formatMessage } = props.intl;
  const history = useHistory();
  const mainContext = useContext(MainContext);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [toast, setToast] = useState(false);
  const [isJobCanceling, setIsJobCanceling] = useState(false);
  const [isJobLoaded, setIsJobLoaded] = useState(false);
  const [job, setJob] = useState({
    id: '',
    jobQueueId: '',
    fileType: '',
    fileLink: '',
    fileName: '',
    jobStatus: '',
    errorMessages: [],
    createdAt: '',
    startedAt: '',
    scheduledAt: '',
    finishedAt: '',
    complete_duration: '',
  });
  const [progress, setProgress] = useState({
    running: '',
    products: {
      isComplete: false,
      progress: 0,
      total: 0,
    },
    custom_collections: {
      isComplete: false,
      progress: 0,
      total: 0,
    },
    smart_collections: {
      isComplete: false,
      progress: 0,
      total: 0,
    },
    customers: {
      isComplete: false,
      progress: 0,
      total: 0,
    },
    discounts: {
      isComplete: false,
      progress: 0,
      total: 0,
    },
    orders: {
      isComplete: false,
      progress: 0,
      total: 0,
    },
    draft_orders: {
      isComplete: false,
      progress: 0,
      total: 0,
    },
    pages: {
      isComplete: false,
      progress: 0,
      total: 0,
    },
    blog_posts: {
      isComplete: false,
      progress: 0,
      total: 0,
    },
    redirects: {
      isComplete: false,
      progress: 0,
      total: 0,
    },
    activity: {
      isComplete: false,
      progress: 0,
      total: 0,
    },
    shop: { isComplete: false, progress: 0, total: 0 },
  });
  const [progressPercent, setProgressPercent] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [progressDuration, setProgressDuration] = useState(0);

  const [exportResult, setExportResult] = useState({});
  const [isSchedule, setIsSchedule] = useState(false);
  const [isRepeat, setIsRepeat] = useState(false);
  const [scheduleHour, setScheduleHour] = useState('0');
  const [scheduleMinute, setScheduleMinute] = useState('0');
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
  });

  const [repeatAfter, setRepeatAfter] = useState('1');
  const [repeatMoment, setRepeatMoment] = useState('days');
  const [repeatTime, setRepeatTime] = useState('until cancelled');
  const [customFileName, setCustomFileName] = useState();
  const [ftpAccountType, setFtpAccountType] = useState('');
  const [ftpAccountValue, setFtpAccountValue] = useState('');

  const [selectedExport, setSelectedExport] = useState([]);

  const secondaryActions = [
    {
      content: formatMessage({
        id: 'back',
      }),
      onAction: () => history.push('/'),
    },
    {
      content: formatMessage({
        id: 'exportAgain',
      }),
      onAction: () => history.push('/new-export'),
    },
  ];

  const jobCancelHandler = () => {
    setIsJobCanceling(true);
    axios
      .post('/cancel_single_job', {
        jobQueueId: job.jobQueueId,
        jobType: 'EXPORT',
        shopName: props.config.shopOrigin,
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = () => {
    axios
      .post('/find_single_job', {
        id: queryParams.job_id,
      })
      .then(({ data }) => {
        if (data.job) {
          setIsJobLoaded(true);
          setJob({
            ...job,
            id: data.job._id,
            jobQueueId: data.job.jobQueueId,
            fileType:
              data.job.fileType === '.xlsx'
                ? 'EXCEL'
                : data.job.fileType === '.csv'
                ? 'CSV'
                : null,
            fileLink: data.job.fileLink,
            fileName: data.job.fileName,
            jobStatus: data.job.jobStatus,
            errorMessages: data.job.errorMessages,
            createdAt:
              data.job.createdAt &&
              moment(new Date(data.job.createdAt)).format('YYYY-MM-DD HH:mm'),
            startedAt:
              data.job.startedAt &&
              moment(new Date(data.job.startedAt)).format('YYYY-MM-DD HH:mm'),
            finishedAt:
              data.job.finishedAt &&
              moment(new Date(data.job.finishedAt)).format('YYYY-MM-DD HH:mm'),
            scheduledAt:
              data.job.scheduledAt &&
              moment(new Date(data.job.scheduledAt)).format('YYYY-MM-DD HH:mm'),
            complete_duration: data.job.complete_duration,
          });
          setExportResult(data.job.exportOptions);
          setSelectedExport(Object.keys(data.job.exportOptions));
          setIsSchedule(data.job.schedule.isSchedule);
          setScheduleHour(data.job.schedule.scheduleHour);
          setScheduleMinute(data.job.schedule.scheduleMinute);
          setSelectedDates({
            start: data.job.schedule.date,
          });
          setIsRepeat(data.job.repeat.isRepeat);
          setRepeatAfter(data.job.repeat.repeatAfter);
          setRepeatMoment(data.job.repeat.repeatMoment);
          setRepeatTime(data.job.repeat.repeatTime);
          setCustomFileName(data.job.customFileName);
          setFtpAccountType(data.job.ftpAccount.ftpAccountType);
          setFtpAccountValue(data.job.ftpAccount.ftpAccountValue);
          setProgress(data.job.progress);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const socket = io();
    if (queryParams.from_export === 'true') {
      setToast(
        `${formatMessage({
          id: 'exportJobStarted',
        })}!`
      );
    }
    fetchData();
    window.scroll(0, 0);

    socket.on(props.config.shopOrigin, (msg) => {
      if (msg.exportJobDone) {
        fetchData();
      }
    });

    if (job.jobQueueId) {
      socket.on(`export-progress-${job.jobQueueId}`, (msg) => {
        if (msg.progress) {
          setProgress(msg.progress);
        }
      });
    }

    return () => {
      socket.off(props.config.shopOrigin);
      if (job.jobQueueId) {
        socket.off(`export-progress-${job.jobQueueId}`);
      }
    };
  }, [job.jobQueueId]);

  useEffect(() => {
    const { items_per_import_export } = mainContext.shopCurrentPlan;

    const totalJob = Object.keys(exportResult).reduce((total, i) => {
      if (typeof progress[i] === 'object') {
        const limit =
          progress[i].total > items_per_import_export?.[i]
            ? items_per_import_export?.[i]
            : progress[i].total;

        return total + limit;
      }
      return total;
    }, 0);
    const progressJob = Object.keys(exportResult).reduce((total, i) => {
      if (typeof progress[i] === 'object') {
        return total + progress[i].progress;
      }
      return total;
    }, 0);

    const jobProgress = (progressJob / totalJob) * 100;
    if (jobProgress.toString() !== 'NaN') {
      setProgressPercent(jobProgress);
      setTimeRemaining((totalJob - progressJob) / 2);
      setProgressDuration(progress.duration / 1000);
    }
  }, [exportResult, mainContext.shopCurrentPlan, progress]);

  useEffect(() => {
    if (Object.keys(mainContext.shopCurrentPlan).length === 0) {
      axios
        .get('/plan_details', {
          params: {
            shopName: props.config.shopOrigin,
          },
        })
        .then(({ data }) => {
          mainContext.setPlanPageLoaded(true);
          mainContext.setPlanConfig(data.planConfig);
          mainContext.setShopCurrentPlan(data.shopCurrentPlan);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mainContext, props.config.shopOrigin]);

  const allItemChoiceList = [
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={ProductsMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'products',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ExportProgressBadge
          running={progress.running}
          categoryObj={progress.products}
          categoryName="products"
        />
      ),
      value: 'products',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={CollectionsMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'customCollections',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ExportProgressBadge
          running={progress.running}
          categoryObj={progress.custom_collections}
          categoryName="custom_collections"
        />
      ),
      value: 'custom_collections',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={CollectionsMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'smartCollections',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ExportProgressBadge
          running={progress.running}
          categoryObj={progress.smart_collections}
          categoryName="smart_collections"
        />
      ),
      value: 'smart_collections',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={CustomersMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'customers',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ExportProgressBadge
          running={progress.running}
          categoryObj={progress.customers}
          categoryName="customers"
        />
      ),
      value: 'customers',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={DiscountsMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'discounts',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ExportProgressBadge
          running={progress.running}
          categoryObj={progress.discounts}
          categoryName="discounts"
        />
      ),
      value: 'discounts',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={OrdersMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'orders',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ExportProgressBadge
          running={progress.running}
          categoryObj={progress.orders}
          categoryName="orders"
        />
      ),
      value: 'orders',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={DraftOrdersMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'draftOrders',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ExportProgressBadge
          running={progress.running}
          categoryObj={progress.draft_orders}
          categoryName="draft_orders"
        />
      ),
      value: 'draft_orders',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={CashDollarMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, marginRight: 5, color: '#313131' }}>
            {formatMessage({
              id: 'payouts',
            })}
          </p>
          <Badge>
            {formatMessage({
              id: 'exportOnly',
            })}
          </Badge>
        </div>
      ),

      value: 'payouts',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={PageMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'pages',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ExportProgressBadge
          running={progress.running}
          categoryObj={progress.pages}
          categoryName="pages"
        />
      ),
      value: 'pages',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={ThemeEditMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'blogPosts',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ExportProgressBadge
          running={progress.running}
          categoryObj={progress.blog_posts}
          categoryName="blog_posts"
        />
      ),
      value: 'blog_posts',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={TransferMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'redirects',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ExportProgressBadge
          running={progress.running}
          categoryObj={progress.redirects}
          categoryName="redirects"
        />
      ),
      value: 'redirects',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={ViewMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, marginRight: 5, color: '#313131' }}>
            {formatMessage({
              id: 'activity',
            })}
          </p>
          <Badge>
            {formatMessage({
              id: 'exportOnly',
            })}
          </Badge>
        </div>
      ),
      helpText: (
        <ExportProgressBadge
          running={progress.running}
          categoryObj={progress.activity}
          categoryName="activity"
        />
      ),
      value: 'activity',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={StoreMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'shop',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ExportProgressBadge
          running={progress.running}
          categoryObj={progress.shop}
          categoryName="shop"
        />
      ),
      value: 'shop',
      disabled: true,
    },
  ];

  const selectedItemChoiceList = Object.keys(exportResult).map((e) => {
    const data = allItemChoiceList.find((singleItem) => singleItem.value === e);
    data.renderChildren = () => (
      <Card title="" sectioned>
        <ChoiceList
          allowMultiple
          choices={exportResult[e].map((e) => ({
            label: capitalize.words(e).replace(/_/gi, ' '),
            value: e,
            disabled: true,
          }))}
          selected={exportResult[e]}
        />
      </Card>
    );
    return data;
  });

  return (
    <Frame>
      {toast && <Toast content={toast} onDismiss={() => setToast(false)} />}
      <Provider config={props.config}>
        <TitleBar
          title={formatMessage({
            id: 'export',
          })}
          secondaryActions={secondaryActions}
          primaryAction={
            job.jobStatus === JobStatus.SCHEDULED ||
            job.jobStatus === JobStatus.CANCELLED
              ? null
              : {
                  content: formatMessage({
                    id: 'downloadExportedFile',
                  }),
                  onAction: () => window.open(job.fileLink),
                  disabled: !job.fileLink,
                }
          }
        />
      </Provider>
      {isJobLoaded ? (
        <Page>
          <br />
          <Stack alignment="center">
            <DisplayText size="large">
              {formatMessage({
                id: 'export',
              })}
            </DisplayText>
            {job.jobStatus === JobStatus.FINISHED && (
              <Badge status="success">
                {formatMessage({
                  id: 'finished',
                })}
              </Badge>
            )}
            {job.jobStatus === JobStatus.IN_PROGRESS && (
              <Badge status="info">
                {progress.running
                  ? formatMessage({
                      id: 'inProgress',
                    })
                  : formatMessage({
                      id: 'queued',
                    })}
              </Badge>
            )}
            {job.jobStatus === JobStatus.SCHEDULED && (
              <Badge status="info">
                {formatMessage({
                  id: 'scheduled',
                })}
              </Badge>
            )}
            {job.jobStatus === JobStatus.FINISHED_LIMITED && (
              <Badge status="warning">
                {formatMessage({
                  id: 'finishedOrLimited',
                })}
              </Badge>
            )}
            {job.jobStatus === JobStatus.CANCELLED && (
              <Badge status="attention">
                {formatMessage({
                  id: 'cancelled',
                })}
              </Badge>
            )}
            {job.jobStatus === JobStatus.FAILED && (
              <Badge status="critical">
                {formatMessage({
                  id: 'failed',
                })}
              </Badge>
            )}
          </Stack>
          <br />
          {job.jobStatus === JobStatus.FINISHED && (
            <>
              <Banner
                title={formatMessage({
                  id: 'exportFinished',
                })}
                status="success"
                action={{
                  content: formatMessage({
                    id: 'downloadedExportedFile',
                  }),
                  onAction: () => window.open(job.fileLink),
                }}
              >
                <p>{job.fileName}</p>
              </Banner>
              <br />
            </>
          )}
          {job.jobStatus === JobStatus.CANCELLED && (
            <>
              <Banner title="Export Cancelled" status="warning">
                <p>
                  {formatMessage({
                    id: 'nothingExported',
                  })}
                  ...
                </p>
              </Banner>
              <br />
            </>
          )}
          {job.jobStatus === JobStatus.FAILED && (
            <>
              <Banner
                title={formatMessage({
                  id: 'exportFailed',
                })}
                status="critical"
                action={
                  job.fileLink
                    ? {
                        content: formatMessage({
                          id: 'downloadedExportedFile',
                        }),
                        onAction: () => window.open(job.fileLink),
                      }
                    : null
                }
              >
                {job.errorMessages.map((singleError, i) => (
                  <p key={i}>{singleError}</p>
                ))}
                <div style={{ marginBottom: 10 }} />
                {job.fileName && <p>{job.fileName}</p>}
              </Banner>
              <br />
            </>
          )}
          {job.jobStatus === JobStatus.FINISHED_LIMITED && (
            <>
              <Banner
                title={formatMessage({
                  id: 'limitedByYourPlan',
                })}
                status="warning"
                action={{
                  content: formatMessage({
                    id: 'upgrade_plan',
                  }),
                  onAction: () => history.push('/plan'),
                }}
              >
                <p>
                  {formatMessage({
                    id: 'jobItemLimited',
                  })}
                </p>
              </Banner>
              <Banner
                title={formatMessage({
                  id: 'exportFinishedOrLimited',
                })}
                status="warning"
                action={{
                  content: formatMessage({
                    id: 'downloadedExportedFile',
                  }),
                  onAction: () => window.open(job.fileLink),
                }}
              >
                <p>{job.fileName}</p>
              </Banner>
              <br />
            </>
          )}
          <JobTopStatusBar
            id={job.id}
            formate={job.fileType}
            createdAt={job.createdAt}
            startedAt={job.startedAt}
            scheduledAt={job.scheduledAt}
            finishedAt={job.finishedAt}
            complete_duration={job.complete_duration}
          />

          {progress.running !== '' && (
            <Card
              sectioned
              title={`${formatMessage({
                id: 'progressing',
              })}...`}
            >
              <ProgressBar progress={progressPercent} />
              <br />
              <Stack spacing="tight">
                <Badge status="new">{Math.round(progressPercent)}%</Badge>
                {timeRemaining && (
                  <Badge status="new">
                    {formatMessage({
                      id: 'remaining',
                    })}
                    : {secondsToHms(timeRemaining)}
                  </Badge>
                )}
                <Badge status="new">
                  {formatMessage({
                    id: 'duration',
                  })}
                  : {secondsToHms(progressDuration)}
                </Badge>
                {Math.round(progressPercent) === 100 && (
                  <Badge status="new">
                    {formatMessage({
                      id: 'savingFile',
                    })}
                    ...
                  </Badge>
                )}
              </Stack>
            </Card>
          )}

          <Card sectioned>
            <div id="SingleExportJob">
              <ChoiceList
                allowMultiple
                choices={selectedItemChoiceList}
                selected={selectedExport}
              />
            </div>
          </Card>

          <ExportBottomOptions
            allDisable={true}
            isSchedule={isSchedule}
            selectedDates={selectedDates}
            scheduleHour={scheduleHour}
            scheduleMinute={scheduleMinute}
            isRepeat={isRepeat}
            repeatAfter={repeatAfter}
            repeatMoment={repeatMoment}
            repeatTime={repeatTime}
            customFileName={customFileName}
            ftpAccountType={ftpAccountType}
            ftpAccountValue={ftpAccountValue}
          />
          <br />
          {job.jobStatus === JobStatus.SCHEDULED ? (
            <ButtonGroup>
              <Button
                loading={isJobCanceling}
                destructive
                onClick={jobCancelHandler}
              >
                {formatMessage({
                  id: 'cancel',
                })}
              </Button>
              <Button onClick={() => history.push('/new-export')}>
                {formatMessage({
                  id: 'exportAgain',
                })}
              </Button>
            </ButtonGroup>
          ) : job.jobStatus === JobStatus.CANCELLED ? (
            <PageActions
              secondaryActions={[
                {
                  content: formatMessage({
                    id: 'exportAgain',
                  }),
                  onAction: () => history.push('/new-export'),
                },
              ]}
            />
          ) : (
            <PageActions
              primaryAction={{
                content: formatMessage({
                  id: 'downloadExportedFile',
                }),
                onAction: () => window.open(job.fileLink),
                disabled: !job.fileLink,
              }}
              secondaryActions={[
                {
                  content: formatMessage({
                    id: 'exportAgain',
                  }),
                  onAction: () => history.push('/new-export'),
                },
              ]}
            />
          )}

          <br />
          <br />
        </Page>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner
            accessibilityLabel="Spinner example"
            size="large"
            color="teal"
          />
        </div>
      )}
    </Frame>
  );
};

export default injectIntl(SingleExportJob);
