import React from 'react';
import { Badge } from '@shopify/polaris';

import { injectIntl } from 'react-intl';

function ImportProgressBadge({ running, categoryObj, categoryName, intl }) {
  const { formatMessage } = intl;
  return (
    <div style={{ paddingRight: 30, display: 'flex' }}>
      {running === categoryName ? (
        <>
          {Boolean(categoryObj.new) && (
            <>
              <Badge status="info">
                {formatMessage(
                  {
                    id: 'createdNumber',
                  },
                  {
                    number: categoryObj.new,
                  }
                )}
              </Badge>
              <div style={{ marginLeft: 10 }} />
            </>
          )}
          {Boolean(categoryObj.updated) && (
            <>
              <Badge status="success">
                {formatMessage(
                  {
                    id: 'updatedNumber',
                  },
                  {
                    number: categoryObj.updated,
                  }
                )}
              </Badge>
              <div style={{ marginLeft: 10 }} />
            </>
          )}
          {Boolean(categoryObj.deleted) && (
            <>
              <Badge status="critical">
                {formatMessage(
                  {
                    id: 'deletedNumber',
                  },
                  {
                    number: categoryObj.deleted,
                  }
                )}
              </Badge>
              <div style={{ marginLeft: 10 }} />
            </>
          )}
          {Boolean(categoryObj.replaced) && (
            <>
              <Badge status="warning">
                {formatMessage(
                  {
                    id: 'replacedNumber',
                  },
                  {
                    number: categoryObj.replaced,
                  }
                )}
              </Badge>
              <div style={{ marginLeft: 10 }} />
            </>
          )}
          {Boolean(categoryObj.ignored) && (
            <>
              <Badge status="warning">
                {formatMessage(
                  {
                    id: 'ignoredNumber',
                  },
                  {
                    number: categoryObj.ignored,
                  }
                )}
              </Badge>
              <div style={{ marginLeft: 10 }} />
            </>
          )}
          {Boolean(categoryObj.failed) && (
            <>
              <Badge status="attention">
                {formatMessage(
                  {
                    id: 'failedNumber',
                  },
                  {
                    number: categoryObj.failed,
                  }
                )}
              </Badge>
              <div style={{ marginLeft: 10 }} />
            </>
          )}
          <Badge
            status="info"
            progress="partiallyComplete"
          >{`${categoryObj.progress} of ${categoryObj.total}`}</Badge>
        </>
      ) : categoryObj.isComplete ? (
        <>
          {Boolean(categoryObj.new) && (
            <>
              <Badge status="info">
                {formatMessage(
                  {
                    id: 'createdNumber',
                  },
                  {
                    number: categoryObj.new,
                  }
                )}
              </Badge>
              <div style={{ marginLeft: 10 }} />
            </>
          )}
          {Boolean(categoryObj.updated) && (
            <>
              <Badge status="success">
                {formatMessage(
                  {
                    id: 'updatedNumber',
                  },
                  {
                    number: categoryObj.updated,
                  }
                )}
              </Badge>
              <div style={{ marginLeft: 10 }} />
            </>
          )}
          {Boolean(categoryObj.deleted) && (
            <>
              <Badge status="critical">
                {formatMessage(
                  {
                    id: 'deletedNumber',
                  },
                  {
                    number: categoryObj.deleted,
                  }
                )}
              </Badge>
              <div style={{ marginLeft: 10 }} />
            </>
          )}
          {Boolean(categoryObj.replaced) && (
            <>
              <Badge status="warning">
                {formatMessage(
                  {
                    id: 'replacedNumber',
                  },
                  {
                    number: categoryObj.replaced,
                  }
                )}
              </Badge>
              <div style={{ marginLeft: 10 }} />
            </>
          )}
          {Boolean(categoryObj.ignored) && (
            <>
              <Badge status="warning">
                {formatMessage(
                  {
                    id: 'ignoredNumber',
                  },
                  {
                    number: categoryObj.ignored,
                  }
                )}
              </Badge>
              <div style={{ marginLeft: 10 }} />
            </>
          )}
          {Boolean(categoryObj.failed) && (
            <>
              <Badge status="attention">
                {formatMessage(
                  {
                    id: 'failedNumber',
                  },
                  {
                    number: categoryObj.failed,
                  }
                )}
              </Badge>
              <div style={{ marginLeft: 10 }} />
            </>
          )}

          <Badge
            status={
              categoryObj.total > categoryObj.progress ? 'critical' : 'success'
            }
            progress="complete"
          >{`${categoryObj.total}`}</Badge>
        </>
      ) : (
        <Badge
          status="new"
          progress="incomplete"
        >{`${categoryObj.total}`}</Badge>
      )}
    </div>
  );
}

export default injectIntl(ImportProgressBadge);
