import React from 'react';
import { Stack, Icon, TextStyle } from '@shopify/polaris';
import capitalize from 'capitalize';

import addCommas from '../util/addCommas';

export default function SinglePlanFileLimit(props) {
  return (
    <React.Fragment>
      <Stack>
        <Stack.Item>
          <Icon source={props.iconName} color="inkLighter" />
        </Stack.Item>
        <Stack.Item fill>
          <TextStyle variation="subdued">{props.name}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <TextStyle variation="subdued">
            <b>
              {typeof props.limit === 'string'
                ? capitalize.words(props.limit)
                : addCommas(props.limit)}
            </b>
          </TextStyle>
        </Stack.Item>
      </Stack>
      <br />
    </React.Fragment>
  );
}
