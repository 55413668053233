// import React, { useState } from 'react';
import React, { Component } from 'react';

export const MainContext = React.createContext();

export default class Provider extends Component {
  state = {
    shop: {},
    homePageJobs: { scheduled: [], failed: [], finished: [], allJobs: [] },
    homePageJobsLoaded: false,
    allJobsPageJobs: [],
    newExportPage: {
      isLoaded: false,
      totalItemsCount: {
        product: 0,
        custom_collections: 0,
        smart_collections: 0,
        customers: 0,
        discounts: 0,
        order: 0,
        draft_orders: 0,
        pages: 0,
        blog_posts: 0,
        redirects: 0,
        activity: 0,
      },
    },
    planPageLoaded: false,
    planConfig: [],
    shopCurrentPlan: {},
  };

  setHomePageJobs = (data) => {
    this.setState({
      homePageJobs: data,
      homePageJobsLoaded: true,
    });
  };
  setShop = (data) => {
    this.setState({
      shop: data,
    });
  };
  setAllJobsPageJobs = (data) => {
    this.setState({
      allJobsPageJobs: data,
    });
  };

  setAllJobsPageJobsWithConcat = (data) => {
    this.setState({
      allJobsPageJobs: this.state.allJobsPageJobs.concat(data),
    });
  };

  setExportPageItemsCount = (data) => {
    this.setState({
      newExportPage: {
        isLoaded: true,
        totalItemsCount: data,
      },
    });
  };

  setPlanConfig = (data) => {
    this.setState({
      planConfig: data,
    });
  };

  setShopCurrentPlan = (data) => {
    this.setState({
      shopCurrentPlan: data,
    });
  };
  setPlanPageLoaded = (data) => {
    this.setState({
      planPageLoaded: data,
    });
  };

  render() {
    return (
      <MainContext.Provider
        value={{
          shop: this.state.shop,
          setShop: this.setShop,
          homePageJobs: this.state.homePageJobs,
          setHomePageJobs: this.setHomePageJobs,
          allJobsPageJobs: this.state.allJobsPageJobs,
          setAllJobsPageJobs: this.setAllJobsPageJobs,
          setAllJobsPageJobsWithConcat: this.setAllJobsPageJobsWithConcat,
          newExportPage: this.state.newExportPage,
          setExportPageItemsCount: this.setExportPageItemsCount,
          planConfig: this.state.planConfig,
          shopCurrentPlan: this.state.shopCurrentPlan,
          setPlanConfig: this.setPlanConfig,
          setShopCurrentPlan: this.setShopCurrentPlan,
          planPageLoaded: this.state.planPageLoaded,
          setPlanPageLoaded: this.setPlanPageLoaded,
        }}
      >
        {this.props.children}
      </MainContext.Provider>
    );
  }
}
