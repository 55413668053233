import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Provider, TitleBar } from '@shopify/app-bridge-react';

import { Banner, Stack, FooterHelp, Spinner } from '@shopify/polaris';

import SinglePlan from '../SinglePlan';

import { MainContext } from '../Provider';

import { injectIntl } from 'react-intl';

function Plan(props) {
  const { formatMessage } = props.intl;

  const history = useHistory();
  const mainContext = useContext(MainContext);

  const [pageLoading, setPageLoading] = useState(!mainContext.planPageLoaded);
  const secondaryActions = [
    {
      content: formatMessage({
        id: 'back',
      }),
      onAction: () => history.goBack(),
    },
  ];

  useEffect(() => {
    axios
      .get('/plan_details', {
        params: {
          shopName: props.config.shopOrigin,
        },
      })
      .then(({ data }) => {
        setPageLoading(false);
        mainContext.setPlanPageLoaded(true);
        mainContext.setPlanConfig(data.planConfig);
        mainContext.setShopCurrentPlan(data.shopCurrentPlan);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.config.shopOrigin]);

  return (
    <React.Fragment>
      <Provider config={props.config}>
        <TitleBar
          title={formatMessage({
            id: 'changePlan',
          })}
          secondaryActions={secondaryActions}
        />
      </Provider>
      {pageLoading ? (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner
            accessibilityLabel="Spinner example"
            size="large"
            color="teal"
          />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <br />
          <br />
          <div style={{ width: '90%' }}>
            <Banner
              title={formatMessage(
                {
                  id: 'currentPlan',
                },
                {
                  planName: mainContext.shopCurrentPlan.name,
                }
              )}
              status="info"
            >
              <p>
                {formatMessage({
                  id: 'paying',
                })}
                :{' '}
                <b>
                  ${mainContext.shopCurrentPlan.price}/
                  {formatMessage({
                    id: 'month',
                  })}
                </b>
              </p>
            </Banner>
          </div>

          <br />
          <Stack distribution="center">
            {mainContext.planConfig.map((singlePlan) => (
              <SinglePlan
                key={singlePlan.id}
                singlePlan={singlePlan}
                config={props.config}
                accessToken={props.accessToken}
                shopCurrentPlan={mainContext.shopCurrentPlan}
                setShopCurrentPlan={mainContext.setShopCurrentPlan}
              />
            ))}
          </Stack>
          <br />
          <FooterHelp>
            {formatMessage({
              id: 'planFooterHelp',
            })}
          </FooterHelp>
          <br />
        </div>
      )}
    </React.Fragment>
  );
}

export default injectIntl(Plan);
