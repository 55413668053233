import React from 'react';
import ReactDOM from 'react-dom';
import './css/style.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import enTranslations from '@shopify/polaris/locales/en.json';
import frTranslations from '@shopify/polaris/locales/fr.json';
import { AppProvider } from '@shopify/polaris';
import cookie from 'cookie';

import Provider from './components/Provider';

import { I18nProvider, LOCALES } from './i18n';

const MyApp = () => {
  const { locale } = cookie.parse(document.cookie);

  return (
    <AppProvider
      i18n={locale?.includes('fr') ? frTranslations : enTranslations}
    >
      <Provider>
        <I18nProvider
          locale={locale?.includes('fr') ? LOCALES.FRENCH : LOCALES.ENGLISH}
        >
          <App />
        </I18nProvider>
      </Provider>
    </AppProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <MyApp />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
