import { LOCALES } from '../locales';

export default {
  [LOCALES.FRENCH]: {
    upgrade_plan: 'Améliorer le plan',
    settings: 'Paramètres',
    scheduled: 'Prévu',
    schedule: 'Programme',
    completed: 'Complété',
    errors: 'Erreurs',
    error: 'Erreur',
    all: 'Tout',
    jobs: 'Jobs',
    allJobs: 'Tous les jobs',
    home: "Page d'accueil",
    plan_changed_notification:
      'Votre compte a été changé aussitôt. Vous utilisez maintenant le plan {planName}. Merci!',
    current_plan_status_text:
      'Vous utilisez maintenant notre plan {planName}. Bravo à eux!',
    want_more:
      "Voulez-vous davantage? Cliquez sur le bouton Mise à jour dessous pour qu'il en soit ainsi.",
    export: 'Exporter',
    export_description:
      "Exportez des données de Shopify en utilisant notre expert d'exportation.",
    new_export: 'Nouvelles exportations',
    scheduledJobs: 'Jobs prévus',
    viewAllScheduledJobs: 'Voyer tous les jobs prévus',
    jobsWithErrors: 'Jobs avec erreurs',
    errorJobs: 'Erreur jobs',
    viewAllJobsWithErrors: 'Voyer tous les jobs avec erreurs',
    completedJobs: 'Jobs complétés',
    finishedJobs: 'Jobs finis',
    viewAllFinishedJobs: 'Voyer tous les jobs finis',
    viewAllJobs: 'Voyer tous les jobs',
    getHelp: "Trouver de l'aide",
    theTIEAppGuides: 'Les guides de l’application TIE',
    howToExport: 'Comment exporter',
    editingYourData: 'Éditer vos données',
    howToImport: 'Comment importer',
    needHelp:
      "Avez-vous besoin d'aide supplémentaire? -> Envoyez-nous un courriel!",
    today: 'Aujourd’hui',
    allTime: 'Tout le temps',
    totalJobs: 'Total d’emplois',
    importExcelCSVZIP: 'S’il vous plaît importez Excel, CSV ou Zip File',
    uploadCancelled: 'Téléchargement annulé',
    invalidURL: 'URL invalide',
    import: 'Importer',
    pastURLToAFile: 'de URL à fichier',
    uploadFromURL: ' Téléchargement de URL',
    uploading: ' Téléchargement en cours',
    cancel: 'Annuler',
    cancelled: 'Annulé',
    every: 'chaque',
    duration: 'Durée',
    second: 'seconde',
    fileType: 'Type de fichier',
    finished: 'Terminé',
    inProgress: 'En cours',
    finishedOrLimited: ' Terminé / Limité',
    readyToImport: 'Prêt à importer',
    failed: 'Échoué',
    back: 'De retour',
    nothingToSeeHere: 'Rien à voir ici!',
    todays: 'D’aujourd’hui',
    fptAccountError: '{ftpAccountType} URL {ftpAccountValue} n’est pas valide',
    basicColumns: 'Rubriques élémentaires',
    inventoryOrVariants: 'Inventaire / Variantes',
    variantCost: 'Variante de coût',
    images: 'Images',
    metafields: 'Métafields',
    slow: 'Lentement',
    rules: 'Règles',
    linkedProducts: 'Produits liés',
    productsCount: 'Compter les produits',
    firstAndLastOrder: 'Première et dernière commande',
    addresses: 'Adresses',
    generateNewActivationURLs: 'Générer des nouveaux URLs d’activation',
    customers: 'Clients',
    lineItems: 'Postes en ligne',
    lineType: 'Type de ligne',
    lineItemsProductData: 'Données des produits postées en ligne',
    refunds: 'Remboursements',
    transactions: 'Transactions',
    fulfillments: 'Accomplissements',
    blog: 'Blog',
    comments: 'Observations',
    otherDetails: 'Autres détails',
    newExport: 'Nouveau export',
    format: 'Format',
    exportAll: 'Tout exporter',
    exportAgain: 'Exporter de nouveau',
    products: 'Produits',
    customCollections: 'Collections sur mesure',
    smartCollections: 'Collections intelligentes',
    discounts: 'Discounts',
    orders: 'Ordres',
    draftOrders: 'Ordres provisoires',
    payouts: 'Paiements',
    exportOnly: 'Exportation seulement',
    pages: 'Pages',
    blogPosts: 'Articles de blog',
    redirects: 'Liens de redirection',
    activity: 'Activité',
    shop: 'Magasin',
    changePlan: 'Changement de plan',
    currentPlan: 'Plan actuel: {planName}',
    paying: 'Paiement',
    month: 'mois',
    planFooterHelp:
      "Vous pouvez annuler le plan payé à tout moment. Vous serez automatiquement facturé(e) pour l'utilisation du mois entier.",
    additionalEmailAlert:
      'Vous pouvez ajouter jusqu’au dix courriels supplémentaires',
    wrongEmailAlert:
      'Courriels incorrects: {emails}. Veuillez fournir des courriels exacts, séparés par une virgule.',
    notificationsSaved: 'Notifications sauvegardées',
    jobStarted: 'Job commencé',
    jobQueued: "Job en file d'attente",
    jobCancelled: 'Job annulé',
    jobFailed: 'Job échoué',
    jobFinished: 'Job terminé',
    notifications: 'Notifications',
    notificationDescribe:
      'Configurez vos notifications pour les événements de votre application sur cette page',
    emailAddress: 'Adresses de courriel',
    save: 'Sauvegarder',
    notificationsToTheFollowingEmailAddresses:
      'Livrer des notifications aux courriels suivants',
    shopOwnerEmail: 'Propriétaire du magasin ({ownerEmail})',
    additionalEmails: 'Courriels supplémentaires',
    additionalEmailHelpText: 'Des courriels séparés par virgule (max 10)',
    events: 'Événements',
    event: ' Événement',
    sendEmailFollowingEvents:
      'Envoyer des courriels seulement pendant les événements suivants Send e-mails only on the following events',
    manual: 'Manuelle',
    exportJobStarted: 'Exporter job commencé',
    downloadExportedFile: 'Télécharger fichier exporté',
    queued: 'En file d’attente',
    exportFinished: 'Export fini',
    downloadedExportedFile: 'Fichier exporté téléchargé',
    nothingExported: 'Rien exporté',
    exportFailed: 'Export échoué',
    limitedByYourPlan: 'Limité par votre plan',
    jobItemLimited:
      'Les postes ont été limités par le plan d’application sélectionné. Pour utiliser des fichiers plus grands\r\n    passez à un plan supérieur et essayez à nouveau.',
    exportFinishedOrLimited: 'Export Fini / Limité',
    progressing: 'Progressant',
    remaining: 'Restant',
    savingFile: 'Sauvegarder fichier',
    importJobStarted: 'Importer job commencé!',
    importAgain: 'Importer à nouveau',
    downloadImportResult: ' Télécharger le résultat de l’importation',
    importFinishedWithErrors: 'Import fini avec erreurs!',
    downloadedImportedFile: ' Télécharger fichier importé',
    importResultBannerText:
      'S’il vous plaît, réviser les colonnes des fichiers obtenus  "Importer résultat" et\r\n    "Importer Commentaire" (par défaut les deux dernières colonnes dans le fichier).',
    importFinished: 'Import fini',
    cannotUnderstandTheUploadedFile:
      'Nous ne pouvons pas comprendre le fichier téléchargé.',
    areYouSureYouAreUsingTheRightFormat:
      'Êtes-vous certain(e/s) d’utiliser le format approprié?',
    importErrorMessageTopAlert:
      'Si vous importez un modèle – assurez-vous que c’est un fichier\r\n    .xlsx ou .csv, et a des fiches avec des noms appropriés\r\n    . Des fiches avec des noms soutenus: ',
    importSupportedTypes:
      'Produits, collections intelligentes, collections personnalisées, clients,\r\n    discounts, ordres provisoires, ordres, pages, articles de blog,\r\n    liens de redirection, magasin.',
    importUpgradeMessage:
      ' Les postes ont été limités par le plan d’application sélectionné. Pour utiliser des fichiers plus grands\r\n    passez à un plan supérieur et essayez à nouveau.',
    importFinishedOrLimitedWithErrors: 'Import fini / Limité avec erreurs!',
    reviewImportFile:
      'S’il vous plaît, réviser les colonnes des fichiers obtenus  "Importer résultat" et\r\n    "Importer Commentaire" (par défaut les deux dernières colonnes dans le fichier).',
    downloadedImportResult:
      'Le fichier téléchargé est le résultat de l’importation',
    downgradeAlert:
      ' Êtes-vous certain(e/s) de vouloir déclasser de {currentPlan} à {newPlan}?',
    downgradeTo: ' Déclasser à {planName}',
    downgradeDescription:
      'Si vous déclasser de {currentPlan} à {newPlan}, vous aurez perdre les caractéristiques énumérées dans le {currentPlan}.',
    select: 'Sélectionner',
    LimitPerFile: 'LIMITÉ PAR FICHIER',
    id: 'ID',
    createdOn: 'Créé le',
    startedAt: 'Commencé à',
    scheduledAt: 'Programmé à',
    finishedAt: 'Fini à',
    uploadedFile: 'Fichier téléchargé',
    createdNumber: 'Créé: {number}',
    deletedNumber: 'Effacé: {number}',
    replacedNumber: 'Remplacé: {number}',
    ignoredNumber: 'Ignoré: {number}',
    failedNumber: 'Échoué: {number}',
    updatedNumber: 'Mis à jour: {number}',
    exportedNumber: 'Exporté: {number}',
    untilCancelled: 'jusqu’à ce que annulé',
    minutes: 'minutes',
    hours: 'heures',
    days: 'jours',
    weeks: 'semaines',
    months: 'mois',
    years: 'ans',
    fileURL: 'Fichier URL',
    willDownloadTheFileWhenTheImportStarts:
      'Va télécharger le fichier quand l’importation commence.',
    freshImportWithoutID: 'Nouvelle importation sans identifiant',
    freshImportWithoutIDTooltip:
      'Veuillez cocher cette case avant d’importer de nouveaux éléments SEULEMENT appartenant à un magasin différent. Ceci va ignorer la colonne ID et hâter votre importation.',
    generateRedirects: 'Générer des liens de redirection',
    generateRedirectsTooltip:
      'Si vous changez la manipulation d’un produit on va générer un lien de redirection',
    transliterateHandles: 'Translittérer des manipulations',
    transliterateHandlesTooltip:
      'Vérifier ceci si votre importation contient des noms de manipulations qui ne sont pas en anglais.',
    continueImportNextDayIfLimited:
      'Continuez l’importation le jour prochain s’il y a des limites',
    continueImportNextDayIfLimitedTooltip:
      'Le job continuera le lendemain si vos variantes dépassent la limite journalière d’importation de Spotify',
    stripImagesFromHTML: 'Décaper les images de HTML',
    stripImagesFromHTMLTooltip:
      'Supprimez les balises d’images du corps de message en HTML',
    respectItemOrderInFile: 'Respectez l’ordre des postes dans le fichier',
    respectItemOrderInFileTooltip:
      'Importez des postes dans le même ordre qu’ils figurent dans votre fichier d’importation',
    dryRun: 'Test',
    dryRunTooltip:
      'Traitez et convertissez votre fichier d’importation SANS vraiment importer les données. Bon pour validation.',
    UploadResultFileTo: 'Télécharger fichier résultat sur:',
    scheduleOn: 'Programmer le:',
    repeatEvery: 'Répéter chaque:',
    run: 'exécuter',
    sortedExport:
      'Exporter les postes rangés dans l’ordre qu’ils viennent de Shopify',
    customFileName: 'Nom de fichier sur mesure',
    dynamicPlaceholders: 'Des espaces réservés changeantes',
    uploadTo: 'Télécharger sur',
  },
};
