import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Provider, TitleBar } from '@shopify/app-bridge-react';
import {
  ProductsMajorMonotone,
  CollectionsMajorMonotone,
  CustomersMajorMonotone,
  DiscountsMajorMonotone,
  DraftOrdersMajorMonotone,
  OrdersMajorMonotone,
  ReceiptMajorMonotone,
  CashDollarMajorMonotone,
  StoreMajorMonotone,
  ViewMajorMonotone,
  TransferMajorMonotone,
  ThemeEditMajorMonotone,
  PageMajorMonotone,
} from '@shopify/polaris-icons';
import {
  Frame,
  Page,
  Card,
  PageActions,
  ChoiceList,
  Select,
  Icon,
  Badge,
  Toast,
  Spinner,
  Stack,
} from '@shopify/polaris';

import axios from 'axios';

import { injectIntl } from 'react-intl';

import * as JobStatus from '../JobStatus';

// import components
import ExportBottomOptions from '../ExportBottomOptions';
import ftpUrlParse from '../../util/ftpUrlParse';

import { MainContext } from '../Provider';

const Export = (props) => {
  const { formatMessage } = props.intl;
  const history = useHistory();
  const mainContext = useContext(MainContext);
  const [formatOptionType, setFormatOptionType] = useState('.xlsx');
  const [isSorted, setIsSorted] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [isRepeat, setIsRepeat] = useState(false);
  const [scheduleHour, setScheduleHour] = useState('0');
  const [scheduleMinute, setScheduleMinute] = useState('0');
  const [repeatAfter, setRepeatAfter] = useState('1');
  const [repeatMoment, setRepeatMoment] = useState('days');
  const [repeatTime, setRepeatTime] = useState('until cancelled');
  const [popoverActive, setPopoverActive] = useState(false);
  const [customFileName, setCustomFileName] = useState('');
  const [ftpAccountType, setFtpAccountType] = useState('');
  const [ftpAccountValue, setFtpAccountValue] = useState('');
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const [toast, setToast] = useState({ error: false, value: '' });

  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const handleFormatOptionTypeChange = useCallback(
    (value) => setFormatOptionType(value),
    []
  );

  const sendExport = (event) => {
    try {
      if (ftpAccountType !== '') {
        ftpUrlParse(ftpAccountValue);
      }
    } catch (err) {
      setToast({
        error: true,
        value: formatMessage(
          {
            id: 'fptAccountError',
          },
          { ftpAccountType, ftpAccountValue }
        ),
      });
      return;
    }
    const options = {
      products: selectedProductOptions,
      custom_collections: selectedCustomCollectionOptions,
      smart_collections: selectedSmartCollectionsOptions,
      customers: selectedCustomerOptions,
      discounts: selectedPriceRuleOptions,
      orders: selectedOrderOptions,
      draft_orders: selectedDraftOrderOptions,
      payouts: selectedPayoutsOptions,
      pages: selectedPageOptions,
      blog_posts: selectedBlogOptions,
      redirects: selectedRedirectsOptions,
      activity: selectedActivityOptions,
      shop: selectedShopOptions,
    };
    const currentDate = new Date();
    const exportOptions = {};
    delete exportOptions.all;

    selectedExport.forEach((selected) => {
      exportOptions[selected] = options[selected];
    });

    const { totalItemsCount } = mainContext.newExportPage;

    const sendData = {
      auth: {
        shopName: props.config.shopOrigin,
        accessToken: props.accessToken,
      },
      exportOptions,
      formatOptionType,
      clientDate: {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
        date: currentDate.getDate(),
        hour: currentDate.getHours(),
        minute: currentDate.getMinutes(),
        seconds: currentDate.getSeconds(),
      },
      schedule: {
        isSchedule,
        scheduleHour,
        scheduleMinute,
        date: selectedDates.start,
        scheduleDate: selectedDates.start.getDate(),
        scheduleMonth: selectedDates.start.getMonth() + 1,
        delay:
          selectedDates.start.setHours(scheduleHour, scheduleMinute) -
          Date.now(),
      },
      repeat: {
        isRepeat,
        repeatAfter,
        repeatMoment,
        repeatTime,
      },
      customFileName: customFileName.replace(/ /g, '_'),
      ftpAccount: {
        ftpAccountType,
        ftpAccountValue,
      },
      progress: {
        running: '',
        products: {
          isComplete: false,
          progress: 0,
          total: totalItemsCount.product,
        },
        custom_collections: {
          isComplete: false,
          progress: 0,
          total: totalItemsCount.custom_collections,
        },
        smart_collections: {
          isComplete: false,
          progress: 0,
          total: totalItemsCount.smart_collections,
        },
        customers: {
          isComplete: false,
          progress: 0,
          total: totalItemsCount.customers,
        },
        discounts: {
          isComplete: false,
          progress: 0,
          total: totalItemsCount.discounts,
        },
        orders: {
          isComplete: false,
          progress: 0,
          total: totalItemsCount.order,
        },
        draft_orders: {
          isComplete: false,
          progress: 0,
          total: totalItemsCount.draft_orders,
        },
        pages: {
          isComplete: false,
          progress: 0,
          total: totalItemsCount.pages,
        },
        blog_posts: {
          isComplete: false,
          progress: 0,
          total: totalItemsCount.blog_posts,
        },
        redirects: {
          isComplete: false,
          progress: 0,
          total: totalItemsCount.redirects,
        },
        activity: {
          isComplete: false,
          progress: 0,
          total: totalItemsCount.activity,
        },
        shop: { isComplete: false, progress: 0, total: 1 },
      },
    };

    setIsExporting(true);

    axios
      .post('/export', sendData)
      .then((response) => {
        setIsExporting(false);
        history.push(
          `/single_export_job?job_id=${response.data.id}&from_export=true`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const secondaryActions = [
    {
      content: 'Back',
      onAction: () => history.goBack(),
    },
  ];
  const formatOptions = [
    { label: 'Excel', value: '.xlsx' },
    { label: 'CSV', value: '.csv' },
  ];

  const SELECT_ALL = [
    'all',
    'products',
    'custom_collections',
    'smart_collections',
    'customers',
    'discounts',
    'orders',
    'draft_orders',
    'payouts',
    'pages',
    'blog_posts',
    'redirects',
    'activity',
    'shop',
  ];

  const TEMP_DISABLED = true;

  const [selectedExport, setSelectedExport] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [isExportDisable, setIsExportDisable] = useState(false);

  useEffect(() => {
    if (selectedExport.length === 0) {
      return setIsExportDisable(true);
    }
    setIsExportDisable(false);
  }, [selectedExport]);

  useEffect(() => {
    axios
      .post('/total_items_counter', {
        shopName: props.config.shopOrigin,
        accessToken: props.accessToken,
      })
      .then(({ data }) => {
        mainContext.setExportPageItemsCount(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleChangeExport = useCallback(
    (value) => {
      if (value.includes('all')) {
        setSelectedExport(SELECT_ALL);

        setSelectedProductOptions(productChoices.map((choice) => choice.value));
        setSelectedCustomCollectionOptions(
          customCollectionChoices.map((choice) => choice.value)
        );
        setSelectedSmartCollectionsOptions(
          smartCollectionChoices.map((choice) => choice.value)
        );
        setSelectedCustomerOptions(
          customerChoices.map((choice) => choice.value)
        );
        setSelectedPriceRuleOptions(
          discountChoices.map((choice) => choice.value)
        );
        setSelectedOrderOptions(orderChoices.map((choice) => choice.value));
        setSelectedDraftOrderOptions(
          draftOrderChoices.map((choice) => choice.value)
        );
        setSelectedPayoutsOptions(payoutsChoices.map((choice) => choice.value));
        setSelectedPageOptions(pageChoices.map((choice) => choice.value));
        handleChangeBlogOptions(blogChoices.map((choice) => choice.value));
        handleChangeRedirectsOptions(
          redirectsChoices.map((choice) => choice.value)
        );
        handleChangeActivityOptions(
          activityChoices.map((choice) => choice.value)
        );
        handleChangeShopOptions(shopChoices.map((choice) => choice.value));
      } else {
        if (selectedExport.includes('all')) {
          setSelectedExport([]);
        } else {
          setSelectedExport(value);
        }
      }
    },
    [selectedExport]
  );

  const productChoices = [
    {
      label: formatMessage({
        id: 'basicColumns',
      }),
      value: 'basic_columns',
      disabled: TEMP_DISABLED,
    },

    {
      label: formatMessage({
        id: 'inventoryOrVariants',
      }),
      value: 'inventory_variants',
    },
    {
      label: formatMessage({
        id: 'variantCost',
      }),
      value: 'variant_cost',
    },

    {
      label: formatMessage({
        id: 'images',
      }),
      value: 'images',
    },
    {
      label: (
        <Stack spacing="extraTight">
          <p>
            {formatMessage({
              id: 'metafields',
            })}
          </p>
          <Badge>
            {formatMessage({
              id: 'slow',
            })}
          </Badge>
        </Stack>
      ),
      value: 'metafields',
    },
  ];

  const smartCollectionChoices = [
    {
      label: formatMessage({
        id: 'basicColumns',
      }),
      value: 'basic_columns',
      disabled: TEMP_DISABLED,
    },
    {
      label: formatMessage({
        id: 'rules',
      }),
      value: 'rules',
    },
    {
      label: formatMessage({
        id: 'linkedProducts',
      }),
      value: 'products',
    },
    {
      label: formatMessage({
        id: 'productsCount',
      }),
      value: 'product_count',
    },
    {
      label: (
        <Stack spacing="extraTight">
          <p>
            {formatMessage({
              id: 'metafields',
            })}
          </p>
          <Badge>
            {formatMessage({
              id: 'slow',
            })}
          </Badge>
        </Stack>
      ),
      value: 'metafields',
    },
  ];

  const customCollectionChoices = [
    {
      label: formatMessage({
        id: 'basicColumns',
      }),
      value: 'basic_columns',
      disabled: TEMP_DISABLED,
    },
    {
      label: formatMessage({
        id: 'linkedProducts',
      }),
      value: 'products',
    },
    {
      label: formatMessage({
        id: 'productsCount',
      }),
      value: 'product_count',
    },
    {
      label: (
        <Stack spacing="extraTight">
          <p>
            {formatMessage({
              id: 'metafields',
            })}
          </p>
          <Badge>
            {formatMessage({
              id: 'slow',
            })}
          </Badge>
        </Stack>
      ),
      value: 'metafields',
    },
  ];

  const customerChoices = [
    {
      label: formatMessage({
        id: 'basicColumns',
      }),
      value: 'basic_columns',
      disabled: TEMP_DISABLED,
    },
    {
      label: formatMessage({
        id: 'firstAndLastOrder',
      }),
      value: 'first_last_order',
    },
    {
      label: formatMessage({
        id: 'addresses',
      }),
      value: 'addresses',
    },
    {
      label: formatMessage({
        id: 'generateNewActivationURLs',
      }),
      value: 'new_activation_url',
    },
    {
      label: (
        <Stack spacing="extraTight">
          <p>
            {formatMessage({
              id: 'metafields',
            })}
          </p>
          <Badge>
            {formatMessage({
              id: 'slow',
            })}
          </Badge>
        </Stack>
      ),
      value: 'metafields',
    },
  ];

  const discountChoices = [
    {
      label: formatMessage({
        id: 'basicColumns',
      }),
      value: 'basic_columns',
      disabled: TEMP_DISABLED,
    },
  ];

  const orderChoices = [
    {
      label: formatMessage({
        id: 'basicColumns',
      }),
      value: 'basic_columns',
      disabled: TEMP_DISABLED,
    },
    {
      label: formatMessage({
        id: 'customers',
      }),
      value: 'customers',
    },
    {
      label: formatMessage({
        id: 'lineItems',
      }),
      value: 'line_items',
    },
    {
      label: formatMessage({
        id: 'lineType',
      }),
      value: 'line_type',
    },
    {
      label: formatMessage({
        id: 'lineItemsProductData',
      }),
      value: 'products',
    },
    {
      label: formatMessage({
        id: 'refunds',
      }),
      value: 'refunds',
    },
    {
      label: formatMessage({
        id: 'transactions',
      }),
      value: 'transactions',
    },
    {
      label: formatMessage({
        id: 'fulfillments',
      }),
      value: 'fulfillments',
    },
    {
      label: (
        <Stack spacing="extraTight">
          <p>
            {formatMessage({
              id: 'metafields',
            })}
          </p>
          <Badge>
            {formatMessage({
              id: 'slow',
            })}
          </Badge>
        </Stack>
      ),
      value: 'metafields',
    },
  ];

  const draftOrderChoices = [
    {
      label: formatMessage({
        id: 'basicColumns',
      }),
      value: 'basic_columns',
      disabled: TEMP_DISABLED,
    },
    {
      label: formatMessage({
        id: 'customers',
      }),
      value: 'customers',
    },
    {
      label: formatMessage({
        id: 'lineItems',
      }),
      value: 'line_items',
    },
    {
      label: formatMessage({
        id: 'lineType',
      }),
      value: 'line_type',
    },
    {
      label: formatMessage({
        id: 'lineItemsProductData',
      }),
      value: 'products',
    },
    {
      label: (
        <Stack spacing="extraTight">
          <p>
            {formatMessage({
              id: 'metafields',
            })}
          </p>
          <Badge>
            {formatMessage({
              id: 'slow',
            })}
          </Badge>
        </Stack>
      ),
      value: 'metafields',
    },
  ];

  const payoutsChoices = [
    {
      label: formatMessage({
        id: 'basicColumns',
      }),
      value: 'basic_columns',
      disabled: TEMP_DISABLED,
    },
    {
      label: formatMessage({
        id: 'transactions',
      }),
      value: 'transactions',
    },
  ];

  const pageChoices = [
    {
      label: formatMessage({
        id: 'basicColumns',
      }),
      value: 'basic_columns',
      disabled: TEMP_DISABLED,
    },
    {
      label: (
        <Stack spacing="extraTight">
          <p>
            {formatMessage({
              id: 'metafields',
            })}
          </p>
          <Badge>
            {formatMessage({
              id: 'slow',
            })}
          </Badge>
        </Stack>
      ),
      value: 'metafields',
    },
  ];

  const blogChoices = [
    {
      label: formatMessage({
        id: 'basicColumns',
      }),
      value: 'basic_columns',
      disabled: TEMP_DISABLED,
    },
    {
      label: formatMessage({
        id: 'blog',
      }),
      value: 'blog',
    },
    {
      label: formatMessage({
        id: 'comments',
      }),
      value: 'comments',
    },
    {
      label: (
        <Stack spacing="extraTight">
          <p>
            {formatMessage({
              id: 'metafields',
            })}
          </p>
          <Badge>
            {formatMessage({
              id: 'slow',
            })}
          </Badge>
        </Stack>
      ),
      value: 'metafields',
    },
  ];

  const redirectsChoices = [
    {
      label: formatMessage({
        id: 'basicColumns',
      }),
      value: 'basic_columns',
      disabled: TEMP_DISABLED,
    },
  ];

  const activityChoices = [
    {
      label: formatMessage({
        id: 'basicColumns',
      }),
      value: 'basic_columns',
      disabled: TEMP_DISABLED,
    },
  ];

  const shopChoices = [
    {
      label: formatMessage({
        id: 'basicColumns',
      }),
      value: 'basic_columns',
      disabled: TEMP_DISABLED,
    },
    {
      label: formatMessage({
        id: 'otherDetails',
      }),
      value: 'other_details',
    },
    {
      label: (
        <Stack spacing="extraTight">
          <p>
            {formatMessage({
              id: 'metafields',
            })}
          </p>
          <Badge>
            {formatMessage({
              id: 'slow',
            })}
          </Badge>
        </Stack>
      ),
      value: 'metafields',
    },
  ];

  const [selectedProductOptions, setSelectedProductOptions] = useState(
    productChoices.map((e) => e.value)
  );

  const handleChangeProductOptions = useCallback((value) => {
    setSelectedProductOptions(value);
  }, []);

  const [
    selectedCustomCollectionOptions,
    setSelectedCustomCollectionOptions,
  ] = useState(customCollectionChoices.map((e) => e.value));

  const handleChangeCustomCollectionOptions = useCallback((value) => {
    setSelectedCustomCollectionOptions(value);
  }, []);

  const [
    selectedSmartCollectionsOptions,
    setSelectedSmartCollectionsOptions,
  ] = useState(smartCollectionChoices.map((e) => e.value));

  const handleChangeSmartCollectionsOptions = useCallback((value) => {
    setSelectedSmartCollectionsOptions(value);
  }, []);

  const [selectedCustomerOptions, setSelectedCustomerOptions] = useState(
    customerChoices.map((e) => e.value)
  );

  const handleChangeCustomerOptions = useCallback((value) => {
    setSelectedCustomerOptions(value);
  }, []);

  const [selectedPriceRuleOptions, setSelectedPriceRuleOptions] = useState(
    discountChoices.map((e) => e.value)
  );

  const handleChangePriceRuleOptions = useCallback((value) => {
    setSelectedPriceRuleOptions(value);
  }, []);

  const [selectedOrderOptions, setSelectedOrderOptions] = useState(
    orderChoices.map((e) => e.value)
  );
  const [selectedDraftOrderOptions, setSelectedDraftOrderOptions] = useState(
    draftOrderChoices.map((e) => e.value)
  );
  const [selectedPayoutsOptions, setSelectedPayoutsOptions] = useState(
    payoutsChoices.map((e) => e.value)
  );
  const [selectedPageOptions, setSelectedPageOptions] = useState(
    pageChoices.map((e) => e.value)
  );
  const [selectedBlogOptions, setSelectedBlogOptions] = useState(
    blogChoices.map((e) => e.value)
  );
  const [selectedRedirectsOptions, setSelectedRedirectsOptions] = useState(
    redirectsChoices.map((e) => e.value)
  );
  const [selectedActivityOptions, setSelectedActivityOptions] = useState(
    activityChoices.map((e) => e.value)
  );
  const [selectedShopOptions, setSelectedShopOptions] = useState(
    shopChoices.map((e) => e.value)
  );

  const handleChangeOrderOptions = useCallback((value) => {
    setSelectedOrderOptions(value);
  }, []);
  const handleChangeDraftOrderOptions = useCallback((value) => {
    setSelectedDraftOrderOptions(value);
  }, []);
  const handleChangePayoutsOptions = useCallback((value) => {
    setSelectedPayoutsOptions(value);
  }, []);
  const handleChangePageOptions = useCallback((value) => {
    setSelectedPageOptions(value);
  }, []);
  const handleChangeBlogOptions = useCallback((value) => {
    setSelectedBlogOptions(value);
  }, []);
  const handleChangeRedirectsOptions = useCallback((value) => {
    setSelectedRedirectsOptions(value);
  }, []);
  const handleChangeActivityOptions = useCallback((value) => {
    setSelectedActivityOptions(value);
  }, []);
  const handleChangeShopOptions = useCallback((value) => {
    setSelectedShopOptions(value);
  }, []);

  const renderProductOptions = useCallback(
    (isSelected) =>
      isSelected && (
        <Card title="" sectioned>
          <ChoiceList
            allowMultiple
            title=""
            choices={productChoices}
            selected={selectedProductOptions}
            onChange={handleChangeProductOptions}
          />
        </Card>
      ),
    [selectedProductOptions]
  );

  const renderSmartCollectionOptions = useCallback(
    (isSelected) =>
      isSelected && (
        <Card title="" sectioned>
          <ChoiceList
            allowMultiple
            title=""
            choices={smartCollectionChoices}
            selected={selectedSmartCollectionsOptions}
            onChange={handleChangeSmartCollectionsOptions}
          />
        </Card>
      ),
    [selectedSmartCollectionsOptions]
  );

  const renderCustomCollectionOptions = useCallback(
    (isSelected) =>
      isSelected && (
        <Card title="" sectioned>
          <ChoiceList
            allowMultiple
            title=""
            choices={customCollectionChoices}
            selected={selectedCustomCollectionOptions}
            onChange={handleChangeCustomCollectionOptions}
          />
        </Card>
      ),
    [selectedCustomCollectionOptions]
  );

  const renderCustomerOptions = useCallback(
    (isSelected) =>
      isSelected && (
        <Card title="" sectioned>
          <ChoiceList
            allowMultiple
            title=""
            choices={customerChoices}
            selected={selectedCustomerOptions}
            onChange={handleChangeCustomerOptions}
          />
        </Card>
      ),
    [selectedCustomerOptions]
  );

  const renderPriceRuleOptions = useCallback(
    (isSelected) =>
      isSelected && (
        <Card title="" sectioned>
          <ChoiceList
            allowMultiple
            title=""
            choices={discountChoices}
            selected={selectedPriceRuleOptions}
            onChange={handleChangePriceRuleOptions}
          />
        </Card>
      ),
    []
  );

  const renderOrderOptions = useCallback(
    (isSelected) =>
      isSelected && (
        <Card title="" sectioned>
          <ChoiceList
            allowMultiple
            title=""
            choices={orderChoices}
            selected={selectedOrderOptions}
            onChange={handleChangeOrderOptions}
          />
        </Card>
      ),
    [selectedOrderOptions]
  );

  const renderDraftOrderOptions = useCallback(
    (isSelected) =>
      isSelected && (
        <Card title="" sectioned>
          <ChoiceList
            allowMultiple
            title=""
            choices={draftOrderChoices}
            selected={selectedDraftOrderOptions}
            onChange={handleChangeDraftOrderOptions}
          />
        </Card>
      ),
    [selectedDraftOrderOptions]
  );

  const renderPayoutsOptions = useCallback(
    (isSelected) =>
      isSelected && (
        <Card title="" sectioned>
          <ChoiceList
            allowMultiple
            title=""
            choices={payoutsChoices}
            selected={selectedPayoutsOptions}
            onChange={handleChangePayoutsOptions}
          />
        </Card>
      ),
    [selectedPayoutsOptions]
  );

  const renderPageOptions = useCallback(
    (isSelected) =>
      isSelected && (
        <Card title="" sectioned>
          <ChoiceList
            allowMultiple
            title=""
            choices={pageChoices}
            selected={selectedPageOptions}
            onChange={handleChangePageOptions}
          />
        </Card>
      ),
    [selectedPageOptions]
  );

  const renderBlogOptions = useCallback(
    (isSelected) =>
      isSelected && (
        <Card title="" sectioned>
          <ChoiceList
            allowMultiple
            title=""
            choices={blogChoices}
            selected={selectedBlogOptions}
            onChange={handleChangeBlogOptions}
          />
        </Card>
      ),
    [selectedBlogOptions]
  );

  const renderRedirectsOptions = useCallback(
    (isSelected) =>
      isSelected && (
        <Card title="" sectioned>
          <ChoiceList
            allowMultiple
            title=""
            choices={redirectsChoices}
            selected={selectedRedirectsOptions}
            onChange={handleChangeRedirectsOptions}
          />
        </Card>
      ),
    [selectedRedirectsOptions]
  );

  const renderActivityOptions = useCallback(
    (isSelected) =>
      isSelected && (
        <Card title="" sectioned>
          <ChoiceList
            allowMultiple
            title=""
            choices={activityChoices}
            selected={selectedActivityOptions}
            onChange={handleChangeActivityOptions}
          />
        </Card>
      ),
    [selectedActivityOptions]
  );

  const renderShopOptions = useCallback(
    (isSelected) =>
      isSelected && (
        <Card title="" sectioned>
          <ChoiceList
            allowMultiple
            title=""
            choices={shopChoices}
            selected={selectedShopOptions}
            onChange={handleChangeShopOptions}
          />
        </Card>
      ),
    [selectedShopOptions]
  );

  const goBack = () => {
    history.push('/');
  };

  return (
    <MainContext.Consumer>
      {({ newExportPage, newExportPage: { totalItemsCount } }) => (
        <Frame>
          {toast.value && (
            <Toast
              error={toast.error}
              content={toast.value}
              onDismiss={() => setToast({ error: false, value: '' })}
            />
          )}
          <Provider config={props.config}>
            <TitleBar
              title={formatMessage({
                id: 'newExport',
              })}
              secondaryActions={secondaryActions}
              primaryAction={{
                content: isSchedule
                  ? formatMessage({
                      id: 'schedule',
                    })
                  : formatMessage({
                      id: 'export',
                    }),
                onAction: sendExport,
                disabled: isExporting || isExportDisable,
              }}
            />
          </Provider>
          {newExportPage.isLoaded ? (
            <Page
              title={formatMessage({
                id: 'newExport',
              })}
            >
              <Card
                sectioned
                title={formatMessage({
                  id: 'format',
                })}
              >
                <Select
                  options={formatOptions}
                  onChange={handleFormatOptionTypeChange}
                  value={formatOptionType}
                />
              </Card>
              <Card sectioned>
                <div id="NewExport">
                  <ChoiceList
                    allowMultiple
                    choices={[
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={ReceiptMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7 }}>
                              {formatMessage({
                                id: 'exportAll',
                              })}
                            </p>
                          </div>
                        ),
                        value: 'all',
                      },
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={ProductsMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7 }}>
                              {formatMessage({
                                id: 'products',
                              })}
                            </p>
                          </div>
                        ),
                        helpText: (
                          <div style={{ paddingRight: 30, display: 'flex' }}>
                            <Badge status="new">
                              {totalItemsCount.product}
                            </Badge>
                          </div>
                        ),
                        value: 'products',
                        renderChildren: renderProductOptions,
                      },
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={CollectionsMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7 }}>
                              {formatMessage({ id: 'customCollections' })}
                            </p>
                          </div>
                        ),
                        helpText: (
                          <div style={{ paddingRight: 30, display: 'flex' }}>
                            <Badge status="new">
                              {totalItemsCount.custom_collections}
                            </Badge>
                          </div>
                        ),
                        value: 'custom_collections',
                        renderChildren: renderCustomCollectionOptions,
                      },
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={CollectionsMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7 }}>
                              {formatMessage({ id: 'smartCollections' })}
                            </p>
                          </div>
                        ),
                        helpText: (
                          <div style={{ paddingRight: 30, display: 'flex' }}>
                            <Badge status="new">
                              {totalItemsCount.smart_collections}
                            </Badge>
                          </div>
                        ),
                        value: 'smart_collections',
                        renderChildren: renderSmartCollectionOptions,
                      },
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={CustomersMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7 }}>
                              {formatMessage({ id: 'customers' })}
                            </p>
                          </div>
                        ),
                        helpText: (
                          <div style={{ paddingRight: 30, display: 'flex' }}>
                            <Badge status="new">
                              {totalItemsCount.customers}
                            </Badge>
                          </div>
                        ),
                        value: 'customers',
                        renderChildren: renderCustomerOptions,
                      },
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={DiscountsMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7 }}>
                              {formatMessage({ id: 'discounts' })}
                            </p>
                          </div>
                        ),
                        helpText: (
                          <div style={{ paddingRight: 30, display: 'flex' }}>
                            <Badge status="new">
                              {totalItemsCount.discounts}
                            </Badge>
                          </div>
                        ),
                        value: 'discounts',
                        renderChildren: renderPriceRuleOptions,
                      },
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={OrdersMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7 }}>
                              {formatMessage({ id: 'orders' })}
                            </p>
                          </div>
                        ),
                        helpText: (
                          <div style={{ paddingRight: 30, display: 'flex' }}>
                            <Badge status="new">{totalItemsCount.order}</Badge>
                          </div>
                        ),
                        value: 'orders',
                        renderChildren: renderOrderOptions,
                      },
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={DraftOrdersMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7 }}>
                              {formatMessage({ id: 'draftOrders' })}
                            </p>
                          </div>
                        ),
                        helpText: (
                          <div style={{ paddingRight: 30, display: 'flex' }}>
                            <Badge status="new">
                              {totalItemsCount.draft_orders}
                            </Badge>
                          </div>
                        ),
                        value: 'draft_orders',
                        renderChildren: renderDraftOrderOptions,
                      },
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={CashDollarMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7, marginRight: 5 }}>
                              {formatMessage({ id: 'payouts' })}
                            </p>
                            <Badge>{formatMessage({ id: 'exportOnly' })}</Badge>
                          </div>
                        ),
                        helpText: (
                          <div style={{ paddingRight: 30, display: 'flex' }}>
                            <Badge status="new">...</Badge>
                          </div>
                        ),
                        value: 'payouts',
                        renderChildren: renderPayoutsOptions,
                      },
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={PageMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7 }}>
                              {formatMessage({ id: 'pages' })}{' '}
                            </p>
                          </div>
                        ),
                        helpText: (
                          <div style={{ paddingRight: 30, display: 'flex' }}>
                            <Badge status="new">{totalItemsCount.pages}</Badge>
                          </div>
                        ),
                        value: 'pages',
                        renderChildren: renderPageOptions,
                      },
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={ThemeEditMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7 }}>
                              {formatMessage({ id: 'blogPosts' })}
                            </p>
                          </div>
                        ),
                        helpText: (
                          <div style={{ paddingRight: 30, display: 'flex' }}>
                            <Badge status="new">
                              {totalItemsCount.blog_posts}
                            </Badge>
                          </div>
                        ),
                        value: 'blog_posts',
                        renderChildren: renderBlogOptions,
                      },
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={TransferMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7 }}>
                              {formatMessage({ id: 'redirects' })}
                            </p>
                          </div>
                        ),
                        helpText: (
                          <div style={{ paddingRight: 30, display: 'flex' }}>
                            <Badge status="new">
                              {totalItemsCount.redirects}
                            </Badge>
                          </div>
                        ),
                        value: 'redirects',
                        renderChildren: renderRedirectsOptions,
                      },
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={ViewMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7, marginRight: 5 }}>
                              {formatMessage({ id: 'activity' })}
                            </p>
                            <Badge>{formatMessage({ id: 'exportOnly' })}</Badge>
                          </div>
                        ),
                        helpText: (
                          <div style={{ paddingRight: 30, display: 'flex' }}>
                            <Badge status="new">
                              {totalItemsCount.activity}
                            </Badge>
                          </div>
                        ),
                        value: 'activity',
                        renderChildren: renderActivityOptions,
                      },
                      {
                        label: (
                          <div style={{ display: 'flex' }}>
                            <Icon
                              source={StoreMajorMonotone}
                              color="inkLighter"
                            />
                            <p style={{ marginLeft: 7 }}>
                              {formatMessage({ id: 'shop' })}
                            </p>
                          </div>
                        ),
                        helpText: (
                          <div style={{ paddingRight: 30, display: 'flex' }}>
                            <Badge status="new">1</Badge>
                          </div>
                        ),
                        value: 'shop',
                        renderChildren: renderShopOptions,
                      },
                    ]}
                    selected={selectedExport}
                    onChange={handleChangeExport}
                  />
                </div>
              </Card>

              <ExportBottomOptions
                isSorted={isSorted}
                setIsSorted={setIsSorted}
                isSchedule={isSchedule}
                setIsSchedule={setIsSchedule}
                popoverActive={popoverActive}
                togglePopoverActive={togglePopoverActive}
                selectedDates={selectedDates}
                month={month}
                year={year}
                handleMonthChange={handleMonthChange}
                setSelectedDates={setSelectedDates}
                scheduleHour={scheduleHour}
                setScheduleHour={setScheduleHour}
                setScheduleMinute={setScheduleMinute}
                scheduleMinute={scheduleMinute}
                isRepeat={isRepeat}
                setIsRepeat={setIsRepeat}
                setRepeatAfter={setRepeatAfter}
                repeatAfter={repeatAfter}
                setRepeatMoment={setRepeatMoment}
                repeatMoment={repeatMoment}
                setRepeatTime={setRepeatTime}
                repeatTime={repeatTime}
                customFileName={customFileName}
                setCustomFileName={setCustomFileName}
                ftpAccountType={ftpAccountType}
                setFtpAccountType={setFtpAccountType}
                ftpAccountValue={ftpAccountValue}
                setFtpAccountValue={setFtpAccountValue}
              />

              <PageActions
                primaryAction={{
                  content: isSchedule
                    ? formatMessage({
                        id: 'schedule',
                      })
                    : formatMessage({
                        id: 'export',
                      }),
                  onAction: sendExport,
                  loading: isExporting,
                  disabled: isExportDisable,
                }}
                secondaryActions={[
                  {
                    content: formatMessage({
                      id: 'back',
                    }),
                    onAction: goBack,
                  },
                ]}
              />
              <br />
              <br />
            </Page>
          ) : (
            <div
              style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner
                accessibilityLabel="Spinner example"
                size="large"
                color="teal"
              />
            </div>
          )}
        </Frame>
      )}
    </MainContext.Consumer>
  );
};

export default injectIntl(Export);
