import { LOCALES } from '../locales';

export default {
  [LOCALES.ENGLISH]: {
    upgrade_plan: 'Upgrade Plan',
    settings: 'Settings',
    scheduled: 'Scheduled',
    schedule: 'Schedule',
    completed: 'Completed',
    errors: 'Errors',
    error: 'Error',
    all: 'All',
    jobs: 'Jobs',
    allJobs: 'All Jobs',
    home: 'Home',
    plan_changed_notification:
      "Your account change has been made without delay. You're now on the {planName} Plan. Thank you!",
    current_plan_status_text: "You're on our {planName} Plan. Jolly Good Show!",
    want_more: 'Want more? Hit the upgrade button below and make it so.',
    export: 'Export',
    export_description: 'Export data from Shopify using our export wizard.',
    new_export: 'New Export',
    scheduledJobs: 'Scheduled Jobs',
    viewAllScheduledJobs: 'View all Scheduled Jobs',
    jobsWithErrors: 'Jobs with Errors',
    errorJobs: 'Error Jobs',
    viewAllJobsWithErrors: 'View all Jobs with Errors',
    completedJobs: 'Completed Jobs',
    finishedJobs: 'Finished Jobs',
    viewAllFinishedJobs: 'View all Finished Jobs',
    viewAllJobs: 'View all Jobs',
    getHelp: 'Get Help',
    theTIEAppGuides: 'The TIE App Guides',
    howToExport: 'How to Export',
    editingYourData: 'Editing Your Data',
    howToImport: 'How to Import',
    needHelp: "Need an extra hand or two? -> Email Us Why Don't You!",
    today: 'Today',
    allTime: 'All Time',
    totalJobs: 'Total Jobs',
    importExcelCSVZIP: 'Please Import Excel, CSV or Zip File',
    uploadCancelled: 'Upload cancelled',
    invalidURL: 'Invalid URL',
    import: 'Import',
    pastURLToAFile: 'Past URL to a File',
    uploadFromURL: 'Upload from URL',
    uploading: 'Uploading',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    every: 'every',
    duration: 'Duration',
    second: 'sec',
    fileType: 'File Type',
    finished: 'Finished',
    inProgress: 'In Progress',
    finishedOrLimited: 'Finished / Limited',
    readyToImport: 'Ready To Import',
    failed: 'Failed',
    back: 'Back',
    nothingToSeeHere: 'Nothing to see here!',
    todays: "Today's",
    fptAccountError: '{ftpAccountType} URL {ftpAccountValue} is not valid',
    basicColumns: 'Basic Columns',
    inventoryOrVariants: 'Inventory / Variants',
    variantCost: 'Variant Cost',
    images: 'Images',
    metafields: 'Metafields',
    slow: 'Slow',
    rules: 'Rules',
    linkedProducts: 'Linked Products',
    productsCount: 'Products Count',
    firstAndLastOrder: 'First and Last Order',
    addresses: 'Addresses',
    generateNewActivationURLs: 'Generate New Activation URLs',
    customers: 'Customers',
    lineItems: 'Line Items',
    lineType: 'Line Type',
    lineItemsProductData: 'Line Items Product Data',
    refunds: 'Refunds',
    transactions: 'Transactions',
    fulfillments: 'Fulfillments',
    blog: 'Blog',
    comments: 'Comments',
    otherDetails: 'Other Details',
    newExport: 'New Export',
    format: 'Format',
    exportAll: 'Export All',
    exportAgain: 'Export Again',
    products: 'Products',
    customCollections: 'Custom Collections',
    smartCollections: 'Smart Collections',
    discounts: 'Discounts',
    orders: 'Orders',
    draftOrders: 'Draft Orders',
    payouts: 'Payouts',
    exportOnly: 'Export Only',
    pages: 'Pages',
    blogPosts: 'Blog Posts',
    redirects: 'Redirects',
    activity: 'Activity',
    shop: 'Shop',
    changePlan: 'Change Plan',
    currentPlan: 'Current Plan: {planName}',
    paying: 'Paying',
    month: 'month',
    planFooterHelp: `You can cancel the paid plan any time. You will be charged for the
    whole month.`,
    additionalEmailAlert: 'You can not add additional email up to 10',
    wrongEmailAlert:
      'Wrong e-mails: {emails}. Please, provide correct e-mail addresses, separated by a comma.',
    notificationsSaved: 'Notifications saved',
    jobStarted: 'Job Started',
    jobQueued: 'Job Queued',
    jobCancelled: 'Job Cancelled',
    jobFailed: 'Job Failed',
    jobFinished: 'Job Finished',
    notifications: 'Notifications',
    notificationDescribe: 'Setup your app event notifications on this page',
    emailAddress: 'E-Mail Addresses',
    save: 'Save',
    notificationsToTheFollowingEmailAddresses:
      'Deliver notifications to the following e-mail addresses',
    shopOwnerEmail: 'Shop owner ({ownerEmail})',
    additionalEmails: 'Additional E-mails',
    additionalEmailHelpText: 'Comma separated email addresses (max 10)',
    events: 'Events',
    event: 'Event',
    sendEmailFollowingEvents: 'Send e-mails only on the following events',
    manual: 'Manual',
    exportJobStarted: 'Export job started',
    downloadExportedFile: 'Download Exported File',
    queued: 'Queued',
    exportFinished: 'Export Finished',
    downloadedExportedFile: 'Downloaded Exported File',
    nothingExported: 'Nothing exported',
    exportFailed: 'Export Failed',
    limitedByYourPlan: 'Limited by your Plan',
    jobItemLimited: `Job item count was limited by selected app Plan. To use larger
    files upgrade to higher plan and run it once more.`,
    exportFinishedOrLimited: 'Export Finished / Limited',
    progressing: 'Progressing',
    remaining: 'Remaining',
    savingFile: 'Saving File',
    importJobStarted: 'Import job started!',
    importAgain: 'Import Again',
    downloadImportResult: 'Download Import Result',
    importFinishedWithErrors: 'Import Finished with Errors!',
    downloadedImportedFile: 'Downloaded Imported File',
    importResultBannerText: `Please, review the results file columns "Import Result" and
    "Import Comment" (by default last two columns in the file).`,
    importFinished: 'Import Finished',
    cannotUnderstandTheUploadedFile: 'Cannot understand the uploaded file.',
    areYouSureYouAreUsingTheRightFormat:
      'Are you sure you are using the right format?',
    importErrorMessageTopAlert: `If you are importing a template - make sure it is an
    .xlsx or .csv file, and has sheets with proper
    names. Supported sheet names: `,
    importSupportedTypes: `Products, Smart Collections, Custom Collections, Customers,
    Discounts, Draft Orders, Orders, Pages, Blog Posts,
    Redirects, Shop.`,
    importUpgradeMessage: `Job item count was limited by selected app Plan. To use larger
    files upgrade to higher plan and run it once more.`,
    importFinishedOrLimitedWithErrors: 'Import Finished / Limited with Errors!',
    reviewImportFile: `Please, review the results file columns "Import Result" and
    "Import Comment" (by default last two columns in the file).`,
    downloadedImportResult: 'Downloaded Import Result',
    downgradeAlert:
      'Do You Really Want to Downgrade from {currentPlan} to {newPlan}?',
    downgradeTo: 'Downgrade To {planName}',
    downgradeDescription: `If you Downgrade from {currentPlan} to {newPlan}, Then You will lose listed features of {currentPlan}.`,
    select: 'Select',
    LimitPerFile: 'LIMIT PER FILE',
    id: 'ID',
    createdOn: 'Created On',
    startedAt: 'Started At',
    scheduledAt: 'Scheduled At',
    finishedAt: 'Finished At',
    uploadedFile: 'Uploaded File',
    createdNumber: 'Created: {number}',
    deletedNumber: 'Deleted: {number}',
    replacedNumber: 'Replaced: {number}',
    ignoredNumber: 'Ignored: {number}',
    failedNumber: 'Failed: {number}',
    updatedNumber: 'Updated: {number}',
    exportedNumber: 'Exported: {number}',
    untilCancelled: 'until cancelled',
    minutes: 'minutes',
    hours: 'hours',
    days: 'days',
    weeks: 'weeks',
    months: 'months',
    years: 'years',
    fileURL: 'File URL',
    willDownloadTheFileWhenTheImportStarts:
      'Will download the file when the import starts.',
    freshImportWithoutID: 'Fresh Import without ID',
    freshImportWithoutIDTooltip:
      'Check this when importing new items ONLY from a different store. It will ignore the ID column and speed up your import.',
    generateRedirects: 'Generate Redirects',
    generateRedirectsTooltip:
      'If changing the handle of a product we will generate a redirect',
    transliterateHandles: 'Transliterate Handles',
    transliterateHandlesTooltip:
      'Check this if your import contains handle names that are not in English.',
    continueImportNextDayIfLimited: 'Continue import next day if limited',
    continueImportNextDayIfLimitedTooltip:
      'If your variants exceed the Shopify daily import limit the job will continue next day',
    stripImagesFromHTML: 'Strip images from HTML',
    stripImagesFromHTMLTooltip: 'Remove image tags from Body HTML',
    respectItemOrderInFile: 'Respect Item Order in File',
    respectItemOrderInFileTooltip:
      'Slower. Import items in the same order as they feature in your import file',
    dryRun: 'Dry Run',
    dryRunTooltip:
      'Process and convert your import file WITHOUT actually importing data. Good for validation.',
    UploadResultFileTo: 'Upload Result file to:',
    scheduleOn: 'Schedule on:',
    repeatEvery: 'Repeat every:',
    run: 'run',
    sortedExport: 'Export items sorted in the order they come from Shopify',
    customFileName: 'Custom file name',
    dynamicPlaceholders: 'Dynamic placeholders',
    uploadTo: 'Upload to',
  },
};
