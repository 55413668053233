import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Card,
  Stack,
  DisplayText,
  TextStyle,
  ButtonGroup,
  Button,
  Spinner,
} from '@shopify/polaris';

import axios from 'axios';

import * as JobStatus from './JobStatus';

import translate from '../i18n/translate';

export default function JobStatusCards(props) {
  const history = useHistory();
  const [cardsTime, setCardsTime] = useState(
    localStorage.getItem('cardsTime') || 'today'
  );
  const [isLoading, setIsLoading] = useState(true);
  const [jobCount, setJobCount] = useState({
    scheduled: 0,
    failed: 0,
    finished: 0,
    allJobs: 0,
  });

  const buttonChangeHandler = (state) => {
    localStorage.setItem('cardsTime', state);
    setCardsTime(state);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`/jobs_counter`, {
        shopName: props.shopName,
        isToday: cardsTime === 'today',
      })
      .then(({ data }) => {
        setIsLoading(false);
        setJobCount(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [cardsTime, props.shopName]);

  return (
    <Stack vertical={true} spacing="tight">
      <ButtonGroup segmented>
        <Button
          primary={cardsTime === 'today'}
          onClick={() => buttonChangeHandler('today')}
        >
          {translate('today')}
        </Button>
        <Button
          primary={cardsTime === 'allTime'}
          onClick={() => buttonChangeHandler('allTime')}
        >
          {translate('allTime')}
        </Button>
      </ButtonGroup>

      <Stack vertical={false} distribution="fillEvenly">
        <div
          onClick={() =>
            history.push(
              `/all-jobs?jobStatus=${JobStatus.SCHEDULED}&isToday=${
                cardsTime === 'today'
              }`
            )
          }
          style={{ width: '100%', cursor: 'pointer' }}
        >
          <Card>
            <Card.Section>
              <div style={{ textAlign: 'center' }}>
                <DisplayText size="medium">
                  {translate('scheduled')}
                </DisplayText>
                {isLoading ? (
                  <Spinner size="small" />
                ) : (
                  <TextStyle variation="strong">
                    <DisplayText size="large">{jobCount.scheduled}</DisplayText>
                  </TextStyle>
                )}
              </div>
            </Card.Section>
          </Card>
        </div>
        <div
          onClick={() =>
            history.push(
              `/all-jobs?jobStatus=${JobStatus.FAILED}&isToday=${
                cardsTime === 'today'
              }`
            )
          }
          style={{ width: '100%', cursor: 'pointer' }}
        >
          <Card>
            <Card.Section>
              <div style={{ textAlign: 'center' }}>
                <DisplayText size="medium">{translate('errors')}</DisplayText>
                {isLoading ? (
                  <Spinner size="small" />
                ) : (
                  <TextStyle variation="negative">
                    <DisplayText size="large">{jobCount.failed}</DisplayText>
                  </TextStyle>
                )}
              </div>
            </Card.Section>
          </Card>
        </div>
        <div
          onClick={() =>
            history.push(
              `/all-jobs?jobStatus=${JobStatus.FINISHED}&isToday=${
                cardsTime === 'today'
              }`
            )
          }
          style={{ width: '100%', cursor: 'pointer' }}
        >
          <Card>
            <Card.Section>
              <div style={{ textAlign: 'center' }}>
                <DisplayText size="medium">
                  {translate('completed')}
                </DisplayText>
                {isLoading ? (
                  <Spinner size="small" />
                ) : (
                  <TextStyle variation="positive">
                    <DisplayText size="large">{jobCount.finished}</DisplayText>
                  </TextStyle>
                )}
              </div>
            </Card.Section>
          </Card>
        </div>
        <div
          onClick={() =>
            history.push(`/all-jobs?isToday=${cardsTime === 'today'}`)
          }
          style={{ width: '100%', cursor: 'pointer' }}
        >
          <Card>
            <Card.Section>
              <div style={{ textAlign: 'center' }}>
                <DisplayText size="medium">
                  {translate('totalJobs')}
                </DisplayText>
                {isLoading ? (
                  <Spinner size="small" />
                ) : (
                  <TextStyle variation="strong">
                    <DisplayText size="large">{jobCount.allJobs}</DisplayText>
                  </TextStyle>
                )}
              </div>
            </Card.Section>
          </Card>
        </div>
      </Stack>
    </Stack>
  );
}
