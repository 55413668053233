import React from 'react';

import { Card, Icon, Badge, Stack, Link } from '@shopify/polaris';

import { CalendarMajorMonotone } from '@shopify/polaris-icons';

import { injectIntl } from 'react-intl';

import secondsToHms from '../util/secondsToHms';

function JobTopStatusBar(props) {
  const { formatMessage } = props.intl;

  return (
    <Card sectioned>
      <div style={{ padding: 15 }}>
        <Stack alignment="center">
          {props.id && (
            <Stack alignment="center" spacing="extraTight">
              <p>
                {formatMessage({
                  id: 'id',
                })}
                :
              </p>
              <Badge>#{props.id}</Badge>
            </Stack>
          )}
          {props.formate && (
            <Stack alignment="center" spacing="extraTight">
              <p>
                {formatMessage({
                  id: 'format',
                })}
                :
              </p>
              <Badge status="info">{props.formate}</Badge>
            </Stack>
          )}

          {props.createdAt && (
            <Stack alignment="center" spacing="extraTight">
              <p>
                {formatMessage({
                  id: 'createdOn',
                })}
                :
              </p>
              <Badge>{props.createdAt}</Badge>
            </Stack>
          )}
          {props.startedAt && (
            <Stack alignment="center" spacing="extraTight">
              <p>
                {formatMessage({
                  id: 'startedAt',
                })}
                :
              </p>
              <Badge>{props.startedAt}</Badge>
            </Stack>
          )}
          {props.scheduledAt && (
            <Stack alignment="center" spacing="extraTight">
              <p>
                {formatMessage({
                  id: 'scheduledAt',
                })}
                :
              </p>
              <Icon source={CalendarMajorMonotone} color="inkLighter" />
              <Badge status="info">{props.scheduledAt}</Badge>
            </Stack>
          )}
          {props.finishedAt && (
            <Stack alignment="center" spacing="extraTight">
              <p>
                {formatMessage({
                  id: 'finishedAt',
                })}
                :
              </p>
              <Badge>{props.finishedAt}</Badge>
            </Stack>
          )}
          {props.complete_duration && (
            <Stack alignment="center" spacing="extraTight">
              <p>
                {formatMessage({
                  id: 'duration',
                })}
                :
              </p>
              <Badge>{secondsToHms(props.complete_duration)}</Badge>
            </Stack>
          )}
          {props.uploadFileName && (
            <Stack alignment="center" spacing="extraTight">
              <p>
                {formatMessage({
                  id: 'uploadedFile',
                })}
                :
              </p>
              <Link url={props.uploadFileLink}>{props.uploadFileName}</Link>
            </Stack>
          )}
        </Stack>
      </div>
    </Card>
  );
}

export default injectIntl(JobTopStatusBar);
