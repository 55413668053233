import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DropZone,
  ProgressBar,
  Card,
  TextField,
  Button,
  Badge,
} from '@shopify/polaris';

import axios from 'axios';

import { injectIntl } from 'react-intl';

import bytesToSize from '../util/bytesToSize';

function DropZoneExample(props) {
  const CancelToken = axios.CancelToken;
  const history = useHistory();
  const [requestCancel, setRequestCancel] = useState({});
  const [files, setFiles] = useState([]);
  const [uploadFromURLLink, setUploadFromURLLink] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadedProgress, setUploadedProgress] = useState(0);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [urlImportButtonLoading, setUrlImportButtonLoading] = useState(false);

  const handleUrlLinkChange = useCallback(
    (newValue) => setUploadFromURLLink(newValue),
    []
  );

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );

  useEffect(() => {
    const form = new FormData();
    const file = files[0];
    if (file) {
      const fileType = file.name.split('.')[file.name.split('.').length - 1];
      if (fileType !== 'xlsx' && fileType !== 'csv' && fileType !== 'zip') {
        props.setErrorToast(
          props.intl.formatMessage({
            id: 'importExcelCSVZIP',
          })
        );
        setFiles([]);
        return;
      }

      setUploadedFileName(file.name);
      form.append('file', file);
      form.append('shopName', props.shopName);

      axios
        .post('/import_file', form, {
          onUploadProgress: ({ loaded, total }) => {
            setUploadedSize(loaded);
            setTotalSize(total);
            setUploadedProgress(Math.round((loaded / total) * 100));
          },
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            setRequestCancel({ cancel: c });
          }),
        })

        .then(({ data }) => {
          setFiles([]);
          history.push(`/single_import_job?job_id=${data.id}`);
        })
        .catch((err) => console.log(err));
    }
  }, [CancelToken, files, history, props]);

  const requestCancelHandler = () => {
    requestCancel.cancel();
    setFiles([]);
    props.setToast(
      props.intl.formatMessage({
        id: 'uploadCancelled',
      })
    );
  };

  const urlImportHandler = () => {
    setUrlImportButtonLoading(true);
    axios
      .post('/import_from_url', {
        shopName: props.shopName,
        uploadFromURLLink,
      })

      .then(({ data }) => {
        setUrlImportButtonLoading(false);
        history.push(`/single_import_job?job_id=${data.id}`);
      })
      .catch(() => {
        setUrlImportButtonLoading(false);
        props.setErrorToast(
          props.intl.formatMessage({
            id: 'invalidURL',
          })
        );
      });
  };

  return (
    <Card
      sectioned
      title={props.intl.formatMessage({
        id: 'import',
      })}
    >
      <>
        {files.length === 0 ? (
          <>
            <DropZone onDrop={handleDropZoneDrop}>
              <DropZone.FileUpload />
            </DropZone>
            <br />
            <TextField
              style={{ flex: 1 }}
              value={uploadFromURLLink}
              onChange={handleUrlLinkChange}
              placeholder={props.intl.formatMessage({
                id: 'pastURLToAFile',
              })}
              connectedRight={
                <div style={{ marginLeft: 15 }}>
                  <Button
                    loading={urlImportButtonLoading}
                    disabled={uploadFromURLLink ? false : true}
                    onClick={urlImportHandler}
                  >
                    {props.intl.formatMessage({
                      id: 'uploadFromURL',
                    })}
                  </Button>
                </div>
              }
            />
          </>
        ) : (
          <>
            <p>
              {props.intl.formatMessage({
                id: 'uploading',
              })}
              : <b>{uploadedFileName}</b>
            </p>
            <br />
            <ProgressBar progress={uploadedProgress} />
            <br />
            <Badge>{uploadedProgress}%</Badge>
            <Badge>
              {bytesToSize(uploadedSize)} of {bytesToSize(totalSize)}
            </Badge>
            <br />
            <br />
            <Button onClick={requestCancelHandler}>
              {props.intl.formatMessage({
                id: 'cancel',
              })}
            </Button>
          </>
        )}
      </>
    </Card>
  );
}

export default injectIntl(DropZoneExample);
