import React from 'react';
import { Badge } from '@shopify/polaris';

import { injectIntl } from 'react-intl';

function ExportProgressBadge({ running, categoryObj, categoryName, intl }) {
  const { formatMessage } = intl;
  return (
    <div style={{ paddingRight: 30, display: 'flex' }}>
      {running === categoryName ? (
        <>
          <Badge status="success">
            {formatMessage(
              {
                id: 'exportedNumber',
              },
              {
                number: categoryObj.progress,
              }
            )}
          </Badge>
          <div style={{ marginLeft: 10 }} />
          <Badge
            status="info"
            progress="partiallyComplete"
          >{`${categoryObj.progress} of ${categoryObj.total}`}</Badge>
        </>
      ) : categoryObj.isComplete ? (
        <>
          <Badge status="success">
            {formatMessage(
              {
                id: 'exportedNumber',
              },
              {
                number: categoryObj.progress,
              }
            )}
          </Badge>
          <div style={{ marginLeft: 10 }} />
          <Badge
            status={
              categoryObj.total > categoryObj.progress ? 'critical' : 'success'
            }
            progress="complete"
          >{`${categoryObj.total}`}</Badge>
        </>
      ) : (
        <Badge
          status="new"
          progress="incomplete"
        >{`${categoryObj.total}`}</Badge>
      )}
    </div>
  );
}

export default injectIntl(ExportProgressBadge);
