import React from 'react';
import {
  Card,
  Select,
  Checkbox,
  Popover,
  Stack,
  Button,
  DatePicker,
  TextField,
  Tooltip,
  Link,
} from '@shopify/polaris';
import moment from 'moment';

import { injectIntl } from 'react-intl';

function ExportBottomOptions(props) {
  const { formatMessage } = props.intl;

  const scheduleHourOptions = [];
  for (let i = 0; i < 24; i++) {
    scheduleHourOptions.push({
      label: i < 10 ? `0${i.toString()}` : i.toString(),
      value: i.toString(),
    });
  }
  const scheduleMinuteOptions = [];
  for (let i = 0; i < 60; i += 5) {
    scheduleMinuteOptions.push({
      label: i < 10 ? `0${i.toString()}` : i.toString(),
      value: i.toString(),
    });
  }

  const repeatAfterOptions = [];
  for (let i = 1; i <= 60; i++) {
    repeatAfterOptions.push({
      label: i.toString(),
      value: i.toString(),
    });
  }

  const repeatTimeOptions = [];
  for (let i = 0; i <= 100; i++) {
    if (i === 0) {
      repeatTimeOptions.push({
        label: formatMessage({
          id: 'untilCancelled',
        }),
        value: 'until cancelled',
      });
      continue;
    }
    repeatTimeOptions.push({
      label: i.toString(),
      value: i.toString(),
    });
  }

  const repeatMomentOptions = [
    {
      label: formatMessage({
        id: 'minutes',
      }),
      value: 'minutes',
    },
    {
      label: formatMessage({
        id: 'hours',
      }),
      value: 'hours',
    },
    {
      label: formatMessage({
        id: 'days',
      }),
      value: 'days',
    },
    {
      label: formatMessage({
        id: 'weeks',
      }),
      value: 'weeks',
    },
    {
      label: formatMessage({
        id: 'months',
      }),
      value: 'months',
    },
    {
      label: formatMessage({
        id: 'years',
      }),
      value: 'years',
    },
  ];
  return (
    <Card sectioned title="Options">
      <div
        style={{
          paddingLeft: 40,
          paddingRight: 40,
        }}
      >
        <Card sectioned subdued>
          <Checkbox
            disabled={props.allDisable}
            label={formatMessage({
              id: 'sortedExport',
            })}
            onChange={() => props.setIsSorted(!props.isSorted)}
            checked={props.isSorted}
          />
          <br />
          <br />

          <Stack spacing="tight" alignment="center">
            <Checkbox
              disabled={props.allDisable}
              label={formatMessage({
                id: 'scheduleOn',
              })}
              checked={props.isSchedule}
              onChange={() => props.setIsSchedule(!props.isSchedule)}
            />

            <Popover
              active={props.popoverActive}
              activator={
                <Button
                  disabled={props.allDisable || !props.isSchedule}
                  onClick={props.togglePopoverActive}
                >
                  {moment(props.selectedDates.start).format('YYYY-MM-DD')}
                </Button>
              }
              onClose={props.togglePopoverActive}
            >
              <Card sectioned>
                <DatePicker
                  disableDatesBefore={new Date(Date.now() - 86400000)}
                  month={props.month}
                  year={props.year}
                  onMonthChange={props.handleMonthChange}
                  selected={props.selectedDates}
                  onChange={(date) => {
                    props.togglePopoverActive();
                    props.setSelectedDates(date);
                  }}
                />
              </Card>
            </Popover>

            <p style={{ color: props.allDisable ? '#919eab' : '' }}>,at</p>
            <Select
              disabled={props.allDisable || !props.isSchedule}
              options={scheduleHourOptions}
              onChange={(v) => props.setScheduleHour(v)}
              value={props.scheduleHour}
            />
            <p style={{ color: props.allDisable ? '#919eab' : '' }}>:</p>
            <Select
              disabled={props.allDisable || !props.isSchedule}
              options={scheduleMinuteOptions}
              onChange={(v) => props.setScheduleMinute(v)}
              value={props.scheduleMinute}
            />
          </Stack>
          <br />
          <Stack spacing="tight" alignment="center">
            <Checkbox
              disabled={props.allDisable}
              label={formatMessage({
                id: 'repeatEvery',
              })}
              checked={props.isRepeat}
              onChange={() => props.setIsRepeat(!props.isRepeat)}
            />
            <Select
              disabled={props.allDisable || !props.isRepeat}
              options={repeatAfterOptions}
              onChange={(v) => props.setRepeatAfter(v)}
              value={props.repeatAfter}
            />
            <Select
              disabled={props.allDisable || !props.isRepeat}
              options={repeatMomentOptions}
              onChange={(v) => props.setRepeatMoment(v)}
              value={props.repeatMoment}
            />
            <p style={{ color: props.allDisable ? '#919eab' : '' }}>
              ,
              {formatMessage({
                id: 'run',
              })}
            </p>
            <Select
              disabled={props.allDisable || !props.isRepeat}
              options={repeatTimeOptions}
              onChange={(v) => props.setRepeatTime(v)}
              value={props.repeatTime}
            />
          </Stack>
          <br />
          <Stack spacing="tight" alignment="center">
            <p style={{ color: props.allDisable ? '#919eab' : '' }}>
              {formatMessage({
                id: 'customFileName',
              })}
              :
            </p>
            <Stack.Item fill>
              <TextField
                disabled={props.allDisable}
                value={props.customFileName}
                placeholder="Export_%Y-%m-%d_%H%M%S"
                onChange={(v) => props.setCustomFileName(v)}
              />
            </Stack.Item>
            <Tooltip
              content="%Y (year), %m (month), %d (day), %H (hour), %M (minute), %S (seconds)"
              preferredPosition="above"
            >
              <Link>
                {formatMessage({
                  id: 'dynamicPlaceholders',
                })}
              </Link>
            </Tooltip>
          </Stack>
          <br />
          <Stack spacing="tight" alignment="center">
            <p style={{ color: props.allDisable ? '#919eab' : '' }}>
              {formatMessage({
                id: 'uploadTo',
              })}
              :
            </p>
            <Select
              disabled={props.allDisable}
              options={[
                {
                  label: '',
                  value: '',
                },
                {
                  label: 'FTP',
                  value: 'FTP',
                },
                {
                  label: 'SFTP',
                  value: 'SFTP',
                },
              ]}
              onChange={(v) => props.setFtpAccountType(v)}
              value={props.ftpAccountType}
            />
            {props.ftpAccountType !== '' && (
              <Stack.Item fill>
                <TextField
                  disabled={props.allDisable}
                  value={props.ftpAccountValue}
                  placeholder={
                    props.ftpAccountType === 'FTP'
                      ? 'ftp://user:password@server:port/path/to/folder/'
                      : 'sftp://user:password@server:port/path/to/folder/'
                  }
                  onChange={(v) => props.setFtpAccountValue(v)}
                />
              </Stack.Item>
            )}
          </Stack>
        </Card>
      </div>
    </Card>
  );
}

export default injectIntl(ExportBottomOptions);
