import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Provider, TitleBar } from '@shopify/app-bridge-react';
import queryString from 'query-string';
import io from 'socket.io-client';
import moment from 'moment-timezone';

import {
  ProductsMajorMonotone,
  CollectionsMajorMonotone,
  CustomersMajorMonotone,
  DiscountsMajorMonotone,
  DraftOrdersMajorMonotone,
  OrdersMajorMonotone,
  CashDollarMajorMonotone,
  TransferMajorMonotone,
  ThemeEditMajorMonotone,
  PageMajorMonotone,
} from '@shopify/polaris-icons';
import {
  Frame,
  Page,
  Card,
  PageActions,
  ChoiceList,
  Icon,
  Badge,
  DisplayText,
  Stack,
  Banner,
  Toast,
  Spinner,
  ProgressBar,
} from '@shopify/polaris';
import axios from 'axios';

import { injectIntl } from 'react-intl';

import * as JobStatus from '../JobStatus';

import { MainContext } from '../Provider';

// import components
import ImportBottomOptions from '../ImportBottomOptions';
import JobTopStatusBar from '../JobTopStatusBar';

import ImportProgressBadge from '../ImportProgressBadge';

import ftpUrlParse from '../../util/ftpUrlParse';

import secondsToHms from '../../util/secondsToHms';

const SingleImportJob = (props) => {
  const { formatMessage } = props.intl;
  const history = useHistory();
  const mainContext = useContext(MainContext);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [toast, setToast] = useState({ error: false, value: '' });
  const [isJobCanceling, setIsJobCanceling] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [isJobLoaded, setIsJobLoaded] = useState(false);
  const [job, setJob] = useState({
    id: '',
    jobQueueId: '',
    fileType: '',
    fileLink: '',
    fileName: '',
    importResultFileLink: '',
    importResultFileName: '',
    jobStatus: '',
    createdAt: '',
    startedAt: '',
    scheduledAt: '',
    finishedAt: '',
    complete_duration: '',
    errorInImport: false,
    errorMessages: [],
  });

  const [progress, setProgress] = useState({
    running: '',
    products: {
      isComplete: false,
      progress: 0,
      total: 0,
      new: 0,
      updated: 0,
      deleted: 0,
      replaced: 0,
      ignored: 0,
      failed: 0,
    },
    custom_collections: {
      isComplete: false,
      progress: 0,
      total: 0,
      new: 0,
      updated: 0,
      deleted: 0,
      replaced: 0,
      ignored: 0,
      failed: 0,
    },
    smart_collections: {
      isComplete: false,
      progress: 0,
      total: 0,
      new: 0,
      updated: 0,
      deleted: 0,
      replaced: 0,
      ignored: 0,
      failed: 0,
    },
    customers: {
      isComplete: false,
      progress: 0,
      total: 0,
      new: 0,
      updated: 0,
      deleted: 0,
      replaced: 0,
      ignored: 0,
      failed: 0,
    },
    discounts: {
      isComplete: false,
      progress: 0,
      total: 0,
      new: 0,
      updated: 0,
      deleted: 0,
      replaced: 0,
      ignored: 0,
      failed: 0,
    },
    orders: {
      isComplete: false,
      progress: 0,
      total: 0,
      new: 0,
      updated: 0,
      deleted: 0,
      replaced: 0,
      ignored: 0,
      failed: 0,
    },
    draft_orders: {
      isComplete: false,
      progress: 0,
      total: 0,
      new: 0,
      updated: 0,
      deleted: 0,
      replaced: 0,
      ignored: 0,
      failed: 0,
    },
    pages: {
      isComplete: false,
      progress: 0,
      total: 0,
      new: 0,
      updated: 0,
      deleted: 0,
      replaced: 0,
      ignored: 0,
      failed: 0,
    },
    blog_posts: {
      isComplete: false,
      progress: 0,
      total: 0,
      new: 0,
      updated: 0,
      deleted: 0,
      replaced: 0,
      ignored: 0,
      failed: 0,
    },
    redirects: {
      isComplete: false,
      progress: 0,
      total: 0,
      new: 0,
      updated: 0,
      deleted: 0,
      replaced: 0,
      ignored: 0,
      failed: 0,
    },
  });
  const [progressPercent, setProgressPercent] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [progressDuration, setProgressDuration] = useState(0);

  const [importResult, setImportResult] = useState([]);
  const [isSchedule, setIsSchedule] = useState(false);
  const [isRepeat, setIsRepeat] = useState(false);
  const [scheduleHour, setScheduleHour] = useState('0');
  const [scheduleMinute, setScheduleMinute] = useState('0');
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [isIgnoreId, setIsIgnoreId] = useState(false);
  const [isGenerateRedirect, setIsGenerateRedirect] = useState(true);
  const [isTranslateToEnglish, setIsTranslateToEnglish] = useState(true);
  const [continueNextDayImport, setContinueNextDayImport] = useState(false);

  const [removeImageFromBodyHtml, setRemoveImageFromBodyHtml] = useState(false);
  const [orderImportItem, setOrderImportItem] = useState(false);
  const [isDryRun, setIsDryRun] = useState(false);

  const [popoverActive, setPopoverActive] = useState(false);

  const [repeatAfter, setRepeatAfter] = useState('1');
  const [repeatMoment, setRepeatMoment] = useState('days');
  const [repeatTime, setRepeatTime] = useState('until cancelled');
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const [selectedImport, setSelectedImport] = useState([]);

  const [ftpAccountType, setFtpAccountType] = useState('');
  const [ftpAccountValue, setFtpAccountValue] = useState('');

  const jobCancelHandler = () => {
    setIsJobCanceling(true);
    axios
      .post('/cancel_single_job', {
        jobQueueId: job.jobQueueId,
        jobType: 'IMPORT',
        id: job.id,
        shopName: props.config.shopOrigin,
        jobStatus: job.jobStatus,
      })
      .then(() => {
        setIsJobCanceling(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const setFetchData = (data) => {
    setJob({
      ...job,
      id: data.job._id,
      jobQueueId: data.job.jobQueueId,
      fileType:
        data.job.fileType === '.xlsx'
          ? 'EXCEL'
          : data.job.fileType === '.csv'
          ? 'CSV'
          : null,
      fileLink: data.job.fileLink,
      fileName: data.job.fileName,
      importResultFileLink: data.job.importResultFileLink,
      importResultFileName: data.job.importResultFileName,
      jobStatus: data.job.jobStatus,
      errorMessages: data.job.errorMessages,
      createdAt:
        data.job.createdAt &&
        moment(new Date(data.job.createdAt)).format('YYYY-MM-DD HH:mm'),
      startedAt:
        data.job.startedAt &&
        moment(new Date(data.job.startedAt)).format('YYYY-MM-DD HH:mm'),
      finishedAt:
        data.job.finishedAt &&
        moment(new Date(data.job.finishedAt)).format('YYYY-MM-DD HH:mm'),
      scheduledAt:
        data.job.scheduledAt &&
        moment(new Date(data.job.scheduledAt)).format('YYYY-MM-DD HH:mm'),
      complete_duration: data.job.complete_duration,
      errorInImport: data.job.errorInImport,
    });
    setImportResult(data.job.importOptions);
    setSelectedImport(data.job.importOptions);
    setProgress(data.job.progress);

    if (data.job?.schedule?.isSchedule) {
      setIsSchedule(data.job.schedule.isSchedule);
      setScheduleHour(data.job.schedule.scheduleHour);
      setScheduleMinute(data.job.schedule.scheduleMinute);
      setSelectedDates({
        start: data.job.schedule.date,
        end: data.job.schedule.date,
      });
    }

    if (data.job?.repeat?.isRepeat) {
      setIsRepeat(data.job.repeat.isRepeat);
      setRepeatAfter(data.job.repeat.repeatAfter);
      setRepeatMoment(data.job.repeat.repeatMoment);
      setRepeatTime(data.job.repeat.repeatTime);
    }
  };

  const importHandler = () => {
    try {
      if (ftpAccountType !== '') {
        ftpUrlParse(ftpAccountValue);
      }
    } catch (err) {
      setToast({
        error: true,
        value: formatMessage(
          {
            id: 'fptAccountError',
          },
          { ftpAccountType, ftpAccountValue }
        ),
      });
      return;
    }
    const sendData = {
      auth: {
        shopName: props.config.shopOrigin,
        accessToken: props.accessToken,
      },
      progress,
      jobId: queryParams.job_id,
      schedule: {
        isSchedule,
        scheduleHour,
        scheduleMinute,
        date: selectedDates.start,
        scheduleDate: selectedDates.start.getDate(),
        scheduleMonth: selectedDates.start.getMonth() + 1,
        delay:
          selectedDates.start.setHours(scheduleHour, scheduleMinute) -
          Date.now(),
      },
      repeat: {
        isRepeat,
        repeatAfter,
        repeatMoment,
        repeatTime,
      },
      ftpAccount: {
        ftpAccountType,
        ftpAccountValue,
      },
    };
    setIsImporting(true);
    axios
      .post('/import', sendData)
      .then(({ data }) => {
        if (data.job) {
          setIsImporting(false);
          setFetchData(data);
          setToast({
            error: false,
            value: formatMessage({
              id: 'importJobStarted',
            }),
          });
          window.scroll(0, 0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchData = () => {
    axios
      .post('/find_single_job', {
        id: queryParams.job_id,
      })
      .then(({ data }) => {
        if (data.job) {
          setIsJobLoaded(true);
          setFetchData(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const importAgain = () => {
    axios
      .post('/import_again', {
        id: queryParams.job_id,
      })
      .then(({ data }) => {
        if (data.job) {
          setFetchData(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const socket = io();
    if (queryParams.from_export === 'true') {
      setToast({ error: false, value: 'Export job started!' });
    }
    fetchData();
    window.scroll(0, 0);
    socket.on(props.config.shopOrigin, (msg) => {
      if (msg.exportJobDone) {
        fetchData();
      }
    });
    socket.on(`import-progress-${queryParams.job_id}`, (msg) => {
      if (msg.progress) {
        setProgress(msg.progress);
      }
    });
    return () => {
      socket.off(props.config.shopOrigin);
      socket.off(`import-progress-${queryParams.job_id}`);
    };
  }, []);

  useEffect(() => {
    const { items_per_import_export } = mainContext.shopCurrentPlan;

    const totalJob = importResult.reduce((total, i) => {
      if (typeof progress[i] === 'object') {
        const limit =
          progress[i].total > items_per_import_export?.[i]
            ? items_per_import_export?.[i]
            : progress[i].total;

        return total + limit;
      }
      return total;
    }, 0);
    const progressJob = importResult.reduce((total, i) => {
      if (typeof progress[i] === 'object') {
        return total + progress[i].progress;
      }
      return total;
    }, 0);

    const jobProgress = (progressJob / totalJob) * 100;
    if (jobProgress.toString() !== 'NaN') {
      setTimeRemaining(totalJob - progressJob);
      setProgressDuration(progress.duration / 1000);
      setProgressPercent(jobProgress);
    }
  }, [importResult, mainContext.shopCurrentPlan, progress]);

  const allItemChoiceList = [
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={ProductsMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'products',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ImportProgressBadge
          running={progress.running}
          categoryObj={progress.products}
          categoryName="products"
        />
      ),
      value: 'products',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={CollectionsMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'customCollections',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ImportProgressBadge
          running={progress.running}
          categoryObj={progress.custom_collections}
          categoryName="custom_collections"
        />
      ),
      value: 'custom_collections',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={CollectionsMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'smartCollections',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ImportProgressBadge
          running={progress.running}
          categoryObj={progress.smart_collections}
          categoryName="smart_collections"
        />
      ),
      value: 'smart_collections',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={CustomersMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'customers',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ImportProgressBadge
          running={progress.running}
          categoryObj={progress.customers}
          categoryName="customers"
        />
      ),
      value: 'customers',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={DiscountsMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'discounts',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ImportProgressBadge
          running={progress.running}
          categoryObj={progress.discounts}
          categoryName="discounts"
        />
      ),
      value: 'discounts',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={OrdersMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'orders',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ImportProgressBadge
          running={progress.running}
          categoryObj={progress.orders}
          categoryName="orders"
        />
      ),
      value: 'orders',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={DraftOrdersMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({
              id: 'draftOrders',
            })}
          </p>
        </div>
      ),
      helpText: (
        <ImportProgressBadge
          running={progress.running}
          categoryObj={progress.draft_orders}
          categoryName="draft_orders"
        />
      ),
      value: 'draft_orders',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={CashDollarMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, marginRight: 5, color: '#313131' }}>
            {formatMessage({ id: 'payouts' })}
          </p>
          <Badge>{formatMessage({ id: 'exportOnly' })}</Badge>
        </div>
      ),

      value: 'payouts',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={PageMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({ id: 'pages' })}
          </p>
        </div>
      ),
      helpText: (
        <ImportProgressBadge
          running={progress.running}
          categoryObj={progress.pages}
          categoryName="pages"
        />
      ),
      value: 'pages',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={ThemeEditMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({ id: 'blogPosts' })}
          </p>
        </div>
      ),
      helpText: (
        <ImportProgressBadge
          running={progress.running}
          categoryObj={progress.blog_posts}
          categoryName="blog_posts"
        />
      ),
      value: 'blog_posts',
      disabled: true,
    },
    {
      label: (
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Icon source={TransferMajorMonotone} color="inkLighter" />
          <p style={{ marginLeft: 7, color: '#313131' }}>
            {formatMessage({ id: 'redirects' })}
          </p>
        </div>
      ),
      helpText: (
        <ImportProgressBadge
          running={progress.running}
          categoryObj={progress.redirects}
          categoryName="redirects"
        />
      ),
      value: 'redirects',
      disabled: true,
    },
  ];

  const selectedItemChoiceList = importResult.map((e) => {
    const data = allItemChoiceList.find((singleItem) => singleItem.value === e);
    return data;
  });

  return (
    <Frame>
      {toast.value && (
        <Toast
          error={toast.error}
          content={toast.value}
          onDismiss={() => setToast({ error: false, value: '' })}
        />
      )}
      <Provider config={props.config}>
        <TitleBar
          title={formatMessage({ id: 'import' })}
          secondaryActions={
            job.jobStatus === JobStatus.CANCELLED ||
            job.jobStatus === JobStatus.FAILED ||
            job.jobStatus === JobStatus.FINISHED ||
            job.jobStatus === JobStatus.FINISHED_LIMITED
              ? [
                  {
                    content: formatMessage({ id: 'back' }),
                    onAction: () => history.push('/'),
                  },
                  {
                    content: formatMessage({ id: 'importAgain' }),
                    onAction: importAgain,
                  },
                ]
              : [
                  {
                    content: formatMessage({ id: 'back' }),
                    onAction: () => history.push('/'),
                  },
                ]
          }
          primaryAction={
            job.jobStatus === JobStatus.READY_TO_IMPORT
              ? {
                  content: isSchedule
                    ? formatMessage({ id: 'schedule' })
                    : formatMessage({ id: 'import' }),
                  onAction: importHandler,
                  disabled: isImporting,
                }
              : job.jobStatus === JobStatus.FINISHED ||
                job.jobStatus === JobStatus.FINISHED_LIMITED
              ? {
                  content: formatMessage({ id: 'downloadImportResult' }),
                  onAction: () => window.open(job.importResultFileLink),
                }
              : null
          }
        />
      </Provider>
      {isJobLoaded ? (
        <Page>
          <br />
          <Stack alignment="center">
            <DisplayText size="large">
              {formatMessage({ id: 'import' })}
            </DisplayText>
            {job.jobStatus === JobStatus.FINISHED && (
              <Badge status="success">
                {formatMessage({ id: 'finished' })}
              </Badge>
            )}
            {job.jobStatus === JobStatus.IN_PROGRESS && (
              <Badge status="info">{formatMessage({ id: 'inProgress' })}</Badge>
            )}
            {job.jobStatus === JobStatus.SCHEDULED && (
              <Badge status="info">{formatMessage({ id: 'scheduled' })}</Badge>
            )}
            {job.jobStatus === JobStatus.FINISHED_LIMITED && (
              <Badge status="warning">
                {formatMessage({ id: 'finishedOrLimited' })}
              </Badge>
            )}
            {job.jobStatus === JobStatus.CANCELLED && (
              <Badge status="attention">
                {formatMessage({ id: 'cancelled' })}
              </Badge>
            )}
            {job.jobStatus === JobStatus.FAILED && (
              <Badge status="warning">{formatMessage({ id: 'failed' })}</Badge>
            )}
            {job.jobStatus === JobStatus.READY_TO_IMPORT && (
              <Badge status="new">
                {formatMessage({ id: 'readyToImport' })}
              </Badge>
            )}
          </Stack>
          <br />
          {job.jobStatus === JobStatus.FINISHED && (
            <>
              {job.errorInImport ? (
                <Banner
                  title={formatMessage({ id: 'importFinishedWithErrors' })}
                  status="warning"
                  action={{
                    content: formatMessage({ id: 'downloadedImportedFile' }),
                    onAction: () => window.open(job.importResultFileLink),
                  }}
                >
                  <p>{formatMessage({ id: 'importResultBannerText' })}</p>
                  {job.errorMessages.map((singleError, i) => (
                    <div key={i}>
                      <div style={{ marginBottom: 10 }} />
                      <b>{singleError}</b>
                    </div>
                  ))}
                  <br />
                  <p>{job.importResultFileName}</p>
                </Banner>
              ) : (
                <Banner
                  title={formatMessage({ id: 'importFinished' })}
                  status="success"
                  action={{
                    content: formatMessage({ id: 'downloadedImportedFile' }),
                    onAction: () => window.open(job.importResultFileLink),
                  }}
                >
                  <p>{job.importResultFileName}</p>
                </Banner>
              )}

              <br />
            </>
          )}

          {job.jobStatus === JobStatus.FAILED && (
            <>
              <Banner title="Import Failed" status="critical">
                <p>
                  {formatMessage({ id: 'cannotUnderstandTheUploadedFile' })}
                </p>
                <p>
                  {formatMessage({ id: 'areYouSureYouAreUsingTheRightFormat' })}
                </p>
                <br />
                <p>
                  {formatMessage({ id: 'importErrorMessageTopAlert' })}
                  <br />
                  <b>{formatMessage({ id: 'importSupportedTypes' })}</b>
                </p>
              </Banner>
              <br />
            </>
          )}
          {job.jobStatus === JobStatus.FINISHED_LIMITED && (
            <>
              <Banner
                title={formatMessage({ id: 'limitedByYourPlan' })}
                status="warning"
                action={{
                  content: formatMessage({ id: 'upgrade_plan' }),
                  onAction: () => history.push('/plan'),
                }}
              >
                <p>{formatMessage({ id: 'importUpgradeMessage' })}</p>
              </Banner>

              {job.errorInImport ? (
                <Banner
                  title={formatMessage({
                    id: 'importFinishedOrLimitedWithErrors',
                  })}
                  status="warning"
                  action={{
                    content: formatMessage({ id: 'downloadedImportedFile' }),
                    onAction: () => window.open(job.importResultFileLink),
                  }}
                >
                  <p>{formatMessage({ id: 'reviewImportFile' })}</p>
                  {job.errorMessages.map((singleError, i) => (
                    <div key={i}>
                      <div style={{ marginBottom: 10 }} />
                      <b>{singleError}</b>
                    </div>
                  ))}
                  <br />
                  <p>{job.importResultFileName}</p>
                </Banner>
              ) : (
                <Banner
                  title={formatMessage({ id: 'importFinished' })}
                  status="warning"
                  action={{
                    content: formatMessage({ id: 'downloadedImportResult' }),
                    onAction: () => window.open(job.importResultFileLink),
                  }}
                >
                  <p>{job.importResultFileName}</p>
                </Banner>
              )}
              <br />
            </>
          )}
          <JobTopStatusBar
            id={job.id}
            formate={job.fileType}
            createdAt={job.createdAt}
            startedAt={job.startedAt}
            scheduledAt={job.scheduledAt}
            finishedAt={job.finishedAt}
            complete_duration={job.complete_duration}
            uploadFileName={job.fileName}
            uploadFileLink={job.fileLink}
          />
          {progress.running !== '' && (
            <Card
              sectioned
              title={`${formatMessage({ id: 'progressing' })}...`}
            >
              <ProgressBar progress={progressPercent} />
              <br />
              <Stack spacing="tight">
                <Badge status="new">{Math.round(progressPercent)}%</Badge>
                {timeRemaining && (
                  <Badge status="new">
                    {formatMessage({ id: 'remaining' })}:{' '}
                    {secondsToHms(timeRemaining)}
                  </Badge>
                )}
                <Badge status="new">
                  {formatMessage({ id: 'duration' })}:{' '}
                  {secondsToHms(progressDuration)}
                </Badge>
                {Math.round(progressPercent) === 100 && (
                  <Badge status="new">
                    {formatMessage({ id: 'savingFile' })}...
                  </Badge>
                )}
              </Stack>
            </Card>
          )}
          {importResult.length !== 0 && (
            <Card sectioned>
              <div id="SingleImportJob">
                <ChoiceList
                  allowMultiple
                  choices={selectedItemChoiceList}
                  selected={selectedImport}
                />
              </div>
            </Card>
          )}
          <ImportBottomOptions
            allDisable={job.jobStatus !== JobStatus.READY_TO_IMPORT}
            isIgnoreId={isIgnoreId}
            setIsIgnoreId={setIsIgnoreId}
            isGenerateRedirect={isGenerateRedirect}
            isTranslateToEnglish={isTranslateToEnglish}
            setIsTranslateToEnglish={setIsTranslateToEnglish}
            continueNextDayImport={continueNextDayImport}
            setContinueNextDayImport={setContinueNextDayImport}
            removeImageFromBodyHtml={removeImageFromBodyHtml}
            setRemoveImageFromBodyHtml={setRemoveImageFromBodyHtml}
            orderImportItem={orderImportItem}
            setOrderImportItem={setOrderImportItem}
            isDryRun={isDryRun}
            setIsDryRun={setIsDryRun}
            setIsGenerateRedirect={setIsGenerateRedirect}
            isSchedule={isSchedule}
            setIsSchedule={setIsSchedule}
            popoverActive={popoverActive}
            togglePopoverActive={togglePopoverActive}
            selectedDates={selectedDates}
            month={month}
            year={year}
            handleMonthChange={handleMonthChange}
            setSelectedDates={setSelectedDates}
            scheduleHour={scheduleHour}
            setScheduleHour={setScheduleHour}
            setScheduleMinute={setScheduleMinute}
            scheduleMinute={scheduleMinute}
            isRepeat={isRepeat}
            setIsRepeat={setIsRepeat}
            setRepeatAfter={setRepeatAfter}
            repeatAfter={repeatAfter}
            setRepeatMoment={setRepeatMoment}
            repeatMoment={repeatMoment}
            setRepeatTime={setRepeatTime}
            repeatTime={repeatTime}
            fileURL={job.fileLink}
            ftpAccountType={ftpAccountType}
            setFtpAccountType={setFtpAccountType}
            ftpAccountValue={ftpAccountValue}
            setFtpAccountValue={setFtpAccountValue}
          />
          <br />
          {job.jobStatus === JobStatus.SCHEDULED ||
          job.jobStatus === JobStatus.IN_PROGRESS ? (
            <PageActions
              secondaryActions={[
                {
                  content: formatMessage({ id: 'cancel' }),
                  onAction: jobCancelHandler,
                  destructive: true,
                  loading: isJobCanceling,
                },
              ]}
            />
          ) : job.jobStatus === JobStatus.CANCELLED ||
            job.jobStatus === JobStatus.FAILED ? (
            <PageActions
              secondaryActions={[
                {
                  content: formatMessage({ id: 'importAgain' }),
                  onAction: importAgain,
                },
              ]}
            />
          ) : job.jobStatus === JobStatus.READY_TO_IMPORT ? (
            <PageActions
              primaryAction={{
                content: isSchedule
                  ? formatMessage({ id: 'schedule' })
                  : formatMessage({ id: 'import' }),
                onAction: importHandler,
                loading: isImporting,
              }}
              secondaryActions={[
                {
                  content: formatMessage({ id: 'cancel' }),
                  onAction: jobCancelHandler,
                  destructive: true,
                  loading: isJobCanceling,
                },
              ]}
            />
          ) : (
            <PageActions
              primaryAction={{
                content: formatMessage({ id: 'downloadImportResult' }),
                onAction: () => window.open(job.importResultFileLink),
              }}
              secondaryActions={[
                {
                  content: formatMessage({ id: 'importAgain' }),
                  onAction: importAgain,
                },
              ]}
            />
          )}

          <br />
          <br />
        </Page>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner
            accessibilityLabel="Spinner example"
            size="large"
            color="teal"
          />
        </div>
      )}
    </Frame>
  );
};

export default injectIntl(SingleImportJob);
