import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import '@shopify/polaris/styles.css';
import cookie from 'cookie';

// import pages
import Home from './components/pages/Home';
import Settings from './components/pages/Settings';
import Plan from './components/pages/Plan';
import AllJobs from './components/pages/AllJobs';
import NewExport from './components/pages/NewExport';
import SingleExportJob from './components/pages/SingleExportJob';
import SingleImportJob from './components/pages/SingleImportJob';
import Welcome from './components/pages/Welcome';
import UpdateScope from './components/pages/UpdateScope';

import PrivateRoute from './components/PrivateRoute';

function App() {
  const { apiKey, shopName, shopNameToken } = cookie.parse(document.cookie);
  let { accessToken } = cookie.parse(document.cookie);
  const hasCookie = apiKey && shopName && accessToken;

  accessToken = atob(accessToken || '');
  const config = {
    apiKey: atob(apiKey || ''),
    shopOrigin: atob(shopName || ''),
  };

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/" hasCookie={hasCookie}>
          <Home config={config} shopNameToken={shopNameToken} />
        </PrivateRoute>
        <PrivateRoute path="/settings" hasCookie={hasCookie}>
          <Settings config={config} shopNameToken={shopNameToken} />
        </PrivateRoute>
        <PrivateRoute path="/plan" hasCookie={hasCookie}>
          <Plan config={config} accessToken={accessToken} />
        </PrivateRoute>
        <PrivateRoute path="/all-jobs" hasCookie={hasCookie}>
          <AllJobs config={config} />
        </PrivateRoute>
        <PrivateRoute path="/new-export" hasCookie={hasCookie}>
          <NewExport config={config} accessToken={accessToken} />
        </PrivateRoute>
        <PrivateRoute path="/single_export_job" hasCookie={hasCookie}>
          <SingleExportJob config={config} />
        </PrivateRoute>
        <PrivateRoute path="/single_import_job" hasCookie={hasCookie}>
          <SingleImportJob config={config} accessToken={accessToken} />
        </PrivateRoute>
        <Route path="/welcome" component={Welcome} />
        <Route path="/update_scope" component={UpdateScope} />
      </Switch>
    </Router>
  );
}

export default App;
