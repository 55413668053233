import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Provider, TitleBar } from '@shopify/app-bridge-react';

import {
  Frame,
  Page,
  Layout,
  Card,
  TextField,
  Checkbox,
  DataTable,
  TextContainer,
  Heading,
  Spinner,
  Toast,
} from '@shopify/polaris';

import validator from 'validator';
import axios from 'axios';

import { injectIntl } from 'react-intl';

function Settings(props) {
  const { formatMessage } = props.intl;
  const history = useHistory();

  const sendMailToOwner = useCheckInput(true);
  const [ownerEmail, setOwnerEmail] = useState('');
  const additionalEmails = useTextInput('');
  const [additionalEmailsFocused, setAdditionalEmailsFocused] = useState(false);
  const jobStartedManual = useCheckInput(true);
  const jobStartedScheduled = useCheckInput(true);
  const jobQueuedManual = useCheckInput(true);
  const jobQueuedScheduled = useCheckInput(true);
  const jobCancelledManual = useCheckInput(true);
  const jobCancelledScheduled = useCheckInput(true);
  const jobFailedManual = useCheckInput(true);
  const jobFailedScheduled = useCheckInput(true);
  const jobFinishedManual = useCheckInput(true);
  const jobFinishedScheduled = useCheckInput(true);

  const [error, setError] = useState('');
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  const [pageLoading, setPageLoading] = useState(true);
  const [toast, setToast] = useState({ error: false, value: '' });

  useEffect(() => {
    axios
      .post('/get_shop_data', { shopNameToken: props.shopNameToken })
      .then(({ data }) => {
        setPageLoading(false);
        additionalEmails.setValue(data.additionalEmails.join(','));
        setOwnerEmail(data.ownerEmail);
        sendMailToOwner.setChecked(data.sendMailToOwner);
        jobStartedManual.setChecked(data.notificationsEvents.jobStarted.manual);
        jobStartedScheduled.setChecked(
          data.notificationsEvents.jobStarted.scheduled
        );
        jobQueuedManual.setChecked(data.notificationsEvents.jobQueued.manual);
        jobQueuedScheduled.setChecked(
          data.notificationsEvents.jobQueued.scheduled
        );
        jobCancelledManual.setChecked(
          data.notificationsEvents.jobCancelled.manual
        );
        jobCancelledScheduled.setChecked(
          data.notificationsEvents.jobCancelled.scheduled
        );
        jobFailedManual.setChecked(data.notificationsEvents.jobFailed.manual);
        jobFailedScheduled.setChecked(
          data.notificationsEvents.jobFailed.scheduled
        );
        jobFinishedManual.setChecked(
          data.notificationsEvents.jobFinished.manual
        );
        jobFinishedScheduled.setChecked(
          data.notificationsEvents.jobFinished.scheduled
        );
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shopNameToken]);

  const handleSaveClick = () => {
    const additionalEmailsValue = additionalEmails.value
      ? additionalEmails.value.split(',').map((e) => e.trim())
      : [];

    if (additionalEmailsValue.length > 10) {
      setError(
        formatMessage({
          id: 'additionalEmailAlert',
        })
      );
      setAdditionalEmailsFocused(true);
      return;
    }

    for (let index = 0; index < additionalEmailsValue.length; index++) {
      const email = additionalEmailsValue[index];
      if (!validator.isEmail(email)) {
        setError(
          formatMessage(
            {
              id: 'wrongEmailAlert',
            },
            {
              emails: additionalEmails.value,
            }
          )
        );
        setAdditionalEmailsFocused(true);
        return;
      }
    }

    setError('');
    setAdditionalEmailsFocused(false);
    setSaveButtonLoading(true);

    const notificationsEvents = {
      jobStarted: {
        manual: jobStartedManual.checked,
        scheduled: jobStartedScheduled.checked,
      },
      jobQueued: {
        manual: jobQueuedManual.checked,
        scheduled: jobQueuedScheduled.checked,
      },
      jobCancelled: {
        manual: jobCancelledManual.checked,
        scheduled: jobCancelledScheduled.checked,
      },
      jobFailed: {
        manual: jobFailedManual.checked,
        scheduled: jobFailedScheduled.checked,
      },
      jobFinished: {
        manual: jobFinishedManual.checked,
        scheduled: jobFinishedScheduled.checked,
      },
    };

    const data = {
      additionalEmails: additionalEmailsValue,
      sendMailToOwner: sendMailToOwner.checked,
      notificationsEvents,
      shopNameToken: props.shopNameToken,
    };

    axios
      .post('/save_shop_settings', data)
      .then(({ data }) => {
        setToast({
          error: false,
          value: formatMessage({
            id: 'notificationsSaved',
          }),
        });
      })
      .catch((err) => {
        setToast({ error: true, value: err.response.data.err });
        console.log(err);
      })
      .finally(() => {
        setSaveButtonLoading(false);
      });
  };

  const secondaryActions = [
    {
      content: formatMessage({
        id: 'back',
      }),
      onAction: () => history.goBack(),
    },
  ];

  const rows = [
    [
      formatMessage({
        id: 'jobStarted',
      }),
      <Checkbox {...jobStartedManual} />,
      <Checkbox {...jobStartedScheduled} />,
    ],
    [
      formatMessage({
        id: 'jobQueued',
      }),
      <Checkbox {...jobQueuedManual} />,
      <Checkbox {...jobQueuedScheduled} />,
    ],
    [
      formatMessage({
        id: 'jobCancelled',
      }),
      <Checkbox {...jobCancelledManual} />,
      <Checkbox {...jobCancelledScheduled} />,
    ],
    [
      formatMessage({
        id: 'jobFailed',
      }),
      <Checkbox {...jobFailedManual} />,
      <Checkbox {...jobFailedScheduled} />,
    ],
    [
      formatMessage({
        id: 'jobFinished',
      }),
      <Checkbox {...jobFinishedManual} />,
      <Checkbox {...jobFinishedScheduled} />,
    ],
  ];

  return (
    <div>
      <Frame>
        {toast.value && (
          <Toast
            error={toast.error}
            content={toast.value}
            onDismiss={() => setToast({ error: false, value: '' })}
          />
        )}
        <Page>
          <Provider config={props.config}>
            <TitleBar
              title={formatMessage({
                id: 'settings',
              })}
              secondaryActions={secondaryActions}
            />
          </Provider>

          {pageLoading ? (
            <div
              style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner
                accessibilityLabel="Spinner example"
                size="large"
                color="teal"
              />
            </div>
          ) : (
            <Layout>
              <Layout.AnnotatedSection
                title={formatMessage({
                  id: 'notifications',
                })}
                description={formatMessage({
                  id: 'notificationDescribe',
                })}
              >
                <Card
                  title={formatMessage({
                    id: 'emailAddress',
                  })}
                  sectioned
                  primaryFooterAction={{
                    content: formatMessage({
                      id: 'save',
                    }),
                    onAction: handleSaveClick,
                    loading: saveButtonLoading,
                  }}
                  footerActionAlignment="left"
                >
                  <p>
                    {formatMessage({
                      id: 'notificationsToTheFollowingEmailAddresses',
                    })}
                    {':'}
                  </p>
                  <br />
                  <Checkbox
                    label={formatMessage(
                      {
                        id: 'shopOwnerEmail',
                      },
                      {
                        ownerEmail: ownerEmail,
                      }
                    )}
                    {...sendMailToOwner}
                  />
                  <br />
                  <br />

                  <TextField
                    {...additionalEmails}
                    focused={additionalEmailsFocused}
                    onBlur={() => setAdditionalEmailsFocused(false)}
                    error={error}
                    label={formatMessage({
                      id: 'additionalEmails',
                    })}
                    type="text"
                    helpText={formatMessage({
                      id: 'additionalEmailHelpText',
                    })}
                  />

                  <br />
                  <TextContainer spacing="tight">
                    <Heading>
                      {formatMessage({
                        id: 'events',
                      })}
                    </Heading>
                    <p>
                      {formatMessage({
                        id: 'sendEmailFollowingEvents',
                      })}
                      :
                    </p>
                  </TextContainer>
                  <DataTable
                    columnContentTypes={['text', 'text', 'text']}
                    headings={[
                      formatMessage({
                        id: 'event',
                      }),
                      formatMessage({
                        id: 'manual',
                      }),
                      formatMessage({
                        id: 'scheduled',
                      }),
                    ]}
                    rows={rows}
                  />
                </Card>
              </Layout.AnnotatedSection>
            </Layout>
          )}
        </Page>
      </Frame>
    </div>
  );
}

function useCheckInput(init) {
  const [checked, setChecked] = useState(init);

  function handleCheckedChange() {
    setChecked(!checked);
  }

  return {
    checked,
    onChange: handleCheckedChange,
    setChecked,
  };
}
function useTextInput(init) {
  const [value, setValue] = useState(init);

  function handleCheckedChange(newValue) {
    setValue(newValue);
  }

  return {
    value,
    onChange: handleCheckedChange,
    setValue,
  };
}

export default injectIntl(Settings);
