import React, { Fragment } from 'react';

import { IntlProvider } from 'react-intl';

import messages from './messages';
import { LOCALES } from './locales';

export default function Provider({ children, locale = LOCALES.ENGLISH }) {
  return (
    <IntlProvider
      locale={locale}
      textComponent={Fragment}
      messages={messages[locale]}
    >
      {children}
    </IntlProvider>
  );
}
