import React, { useState, useEffect, useCallback } from 'react';

import {
  Card,
  Button,
  Stack,
  Subheading,
  DisplayText,
  TextStyle,
  Modal,
  TextContainer,
} from '@shopify/polaris';

import {
  ProductsMajorMonotone,
  CollectionsMajorMonotone,
  CustomersMajorMonotone,
  DiscountsMajorMonotone,
  DraftOrdersMajorMonotone,
  OrdersMajorMonotone,
  CashDollarMajorMonotone,
  StoreMajorMonotone,
  ViewMajorMonotone,
  TransferMajorMonotone,
  PageMajorMonotone,
  BlogMajorMonotone,
} from '@shopify/polaris-icons';

import axios from 'axios';

import { injectIntl } from 'react-intl';

import SinglePlanFileLimit from './SinglePlanFileLimit';

function SinglePlan(props) {
  const { formatMessage } = props.intl;
  const { items_per_import_export: limit, price, name, id } = props.singlePlan;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [downGradeButtonLoading, setDownGradeButtonLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [modalActive, setModalActive] = useState(false);

  const handleModalChange = useCallback(() => setModalActive(!modalActive), [
    modalActive,
  ]);

  useEffect(() => {
    if (props.shopCurrentPlan.id === props.singlePlan.id) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [props.shopCurrentPlan.id, props.singlePlan.id]);

  const finalSubscribePlanHandler = () => {
    setButtonLoading(true);
    setDownGradeButtonLoading(true);
    axios
      .post('/subscribe_plan', {
        planId: id,
        accessToken: props.accessToken,
        shopName: props.config.shopOrigin,
      })
      .then(({ data }) => {
        if (data.confirmation_url) {
          window.top.window.location = data.confirmation_url;
        } else if (data.activeFreePlan) {
          handleModalChange();
          setButtonLoading(false);
          setDownGradeButtonLoading(false);
          props.setShopCurrentPlan(data.currentPlan);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const subscribePlanHandler = () => {
    if (props.shopCurrentPlan.price > props.singlePlan.price) {
      return handleModalChange();
    }
    finalSubscribePlanHandler();
  };

  return (
    <React.Fragment>
      <Modal
        open={modalActive}
        onClose={handleModalChange}
        title={formatMessage(
          {
            id: 'downgradeAlert',
          },
          {
            currentPlan: props.shopCurrentPlan.name,
            newPlan: props.singlePlan.name,
          }
        )}
        primaryAction={{
          loading: downGradeButtonLoading,
          content: formatMessage(
            {
              id: 'downgradeTo',
            },
            {
              planName: props.singlePlan.name,
              newPlan: props.singlePlan.name,
            }
          ),
          onAction: finalSubscribePlanHandler,
        }}
        secondaryActions={[
          {
            content: formatMessage({
              id: 'cancel',
            }),
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              {formatMessage(
                {
                  id: 'downgradeDescription',
                },
                {
                  currentPlan: props.shopCurrentPlan.name,
                  newPlan: props.singlePlan.name,
                }
              )}
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>

      <Card>
        <Card.Section>
          <Stack>
            <Stack.Item fill>
              <DisplayText size="large">{name}</DisplayText>
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation="positive">
                <DisplayText size="large" style={{ color: 'green' }}>
                  ${price}
                </DisplayText>
              </TextStyle>
              {price !== 0 ? '/month' : <br />}
            </Stack.Item>
          </Stack>
        </Card.Section>
        <Card.Section>
          <Button
            primary
            fullWidth
            loading={buttonLoading}
            onClick={subscribePlanHandler}
            disabled={isDisabled}
          >
            {formatMessage({
              id: 'select',
            })}
          </Button>
        </Card.Section>
        <Card.Section
          subdued
          title={
            <Subheading>
              {formatMessage({
                id: 'LimitPerFile',
              })}
            </Subheading>
          }
        >
          <SinglePlanFileLimit
            name={formatMessage({
              id: 'products',
            })}
            limit={limit?.products}
            iconName={ProductsMajorMonotone}
          />
          <SinglePlanFileLimit
            name={formatMessage({ id: 'smartCollections' })}
            limit={limit?.smart_collections}
            iconName={CollectionsMajorMonotone}
          />
          <SinglePlanFileLimit
            name={formatMessage({ id: 'customCollections' })}
            limit={limit?.custom_collections}
            iconName={CollectionsMajorMonotone}
          />
          <SinglePlanFileLimit
            name={formatMessage({ id: 'customers' })}
            limit={limit?.customers}
            iconName={CustomersMajorMonotone}
          />
          <SinglePlanFileLimit
            name={formatMessage({ id: 'discounts' })}
            limit={limit?.discounts}
            iconName={DiscountsMajorMonotone}
          />
          <SinglePlanFileLimit
            name={formatMessage({ id: 'draftOrders' })}
            limit={limit?.draft_orders}
            iconName={DraftOrdersMajorMonotone}
          />
          <SinglePlanFileLimit
            name={formatMessage({ id: 'orders' })}
            limit={limit?.orders}
            iconName={OrdersMajorMonotone}
          />
          <SinglePlanFileLimit
            name={formatMessage({ id: 'pages' })}
            limit={limit?.pages}
            iconName={PageMajorMonotone}
          />
          <SinglePlanFileLimit
            name={formatMessage({ id: 'blogPosts' })}
            limit={limit?.blog_posts}
            iconName={BlogMajorMonotone}
          />
          <SinglePlanFileLimit
            name={formatMessage({ id: 'redirects' })}
            limit={limit?.redirects}
            iconName={TransferMajorMonotone}
          />
          <SinglePlanFileLimit
            name={formatMessage({ id: 'payouts' })}
            limit={limit?.payouts}
            iconName={CashDollarMajorMonotone}
          />
          <SinglePlanFileLimit
            name={formatMessage({ id: 'activity' })}
            limit={limit?.activity}
            iconName={ViewMajorMonotone}
          />
          <SinglePlanFileLimit
            name={formatMessage({ id: 'shop' })}
            limit={limit?.shop}
            iconName={StoreMajorMonotone}
          />
        </Card.Section>
        <Card.Section>
          <Button
            primary
            fullWidth
            loading={buttonLoading}
            onClick={subscribePlanHandler}
            disabled={isDisabled}
          >
            {formatMessage({ id: 'select' })}
          </Button>
        </Card.Section>
      </Card>
    </React.Fragment>
  );
}

export default injectIntl(SinglePlan);
