import React from 'react';
import queryString from 'query-string';

import { Banner, List } from '@shopify/polaris';

export default function UpdateScope() {
  const { installUrl } = queryString.parse(window.location.search);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
      }}
    >
      <Banner
        title="We need additional API permissions"
        action={{
          content: 'Grand API Permissions',
          onAction: () => window.open(atob(installUrl)),
        }}
        status="warning"
      >
        <p>
          This app needs to upgrade scope to function correctly. Please ensure
          you're logged in as the store owner and grant requested permissions.
        </p>
        <br />
        <p>
          Please make sure you are logged in as a store owner and grant those
          permissions to the app!
        </p>
      </Banner>
    </div>
  );
}
