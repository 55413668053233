import React, { useState } from 'react';
import {
  Card,
  ResourceItem,
  TextStyle,
  Avatar,
  ResourceList,
  Badge,
  Stack,
  Link,
  Button,
  Icon,
} from '@shopify/polaris';

import { CalendarMajorMonotone, ReplayMinor } from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';
import capitalize from 'capitalize';
import moment from 'moment-timezone';
import axios from 'axios';

import * as JobStatus from './JobStatus';

import translate from '../i18n/translate';

export default function Jobs({ data, title }) {
  const history = useHistory();
  const [isJobCanceling, setIsJobCanceling] = useState(false);
  const jobCancelHandler = (e, jobType, jobQueueId, id, shopName) => {
    e.stopPropagation();

    setIsJobCanceling(true);
    axios
      .post('/cancel_single_job', {
        jobQueueId,
        jobType: jobType,
        id,
        shopName,
      })
      .then(() => {
        setIsJobCanceling(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const jobClickHandler = (jobId, jobType) => {
    if (jobType === 'EXPORT') {
      return history.push(`/single_export_job?job_id=${jobId}`);
    }
    if (jobType === 'IMPORT') {
      return history.push(`/single_import_job?job_id=${jobId}`);
    }
  };
  return (
    <Card>
      <ResourceList
        resourceName={{ singular: 'job', plural: 'jobs' }}
        items={data}
        renderItem={(item) => {
          let title;
          const { _id, jobType } = item;
          if (jobType === 'EXPORT') {
            title = capitalize.words(
              Object.keys(item.exportOptions).join(', ').replace(/_/g, ' ')
            );
          } else {
            // title = capitalize.words(item.importOptions);
            title = capitalize.words(
              item.importOptions?.join(', ')?.replace(/_/g, ' ') || ''
            );
          }

          const media = (
            <>
              <Avatar
                size="large"
                name={title}
                source={
                  jobType === 'IMPORT'
                    ? '/assets/import.png'
                    : '/assets/export.png'
                }
              />

              <p style={{ marginTop: 10, marginLeft: 10 }}>
                {jobType === 'EXPORT'
                  ? translate('export')
                  : translate('import')}
              </p>
            </>
          );

          return (
            <ResourceItem
              id={_id}
              url={null}
              onClick={() => jobClickHandler(_id, jobType)}
              media={media}
              accessibilityLabel={`View details for ${title}`}
            >
              <Stack vertical={true}>
                <Stack.Item fill>
                  <h3>
                    <TextStyle variation="strong">{title}</TextStyle>
                  </h3>
                </Stack.Item>
                <Stack>
                  <Stack.Item fill>
                    {item.scheduledAt && (
                      <>
                        <Stack alignment="center" spacing="extraTight">
                          <Icon
                            source={CalendarMajorMonotone}
                            color="inkLighter"
                          />
                          <Badge>
                            {moment(new Date(item.scheduledAt)).format(
                              'YYYY-MM-DD HH:mm'
                            )}
                          </Badge>
                        </Stack>
                      </>
                    )}

                    {!item.scheduledAt && item.finishedAt && (
                      <>
                        <div />
                        <Badge>
                          {moment(new Date(item.finishedAt)).format(
                            'YYYY-MM-DD HH:mm'
                          )}
                        </Badge>
                      </>
                    )}
                    {(item.jobStatus === JobStatus.FAILED ||
                      item.jobStatus === JobStatus.READY_TO_IMPORT) &&
                      item.createdAt && (
                        <>
                          <div />
                          <Badge>
                            {moment(new Date(item.createdAt)).format(
                              'YYYY-MM-DD HH:mm'
                            )}
                          </Badge>
                        </>
                      )}

                    {item.repeat?.isRepeat && (
                      <>
                        <div style={{ marginTop: 5 }} />
                        <Stack alignment="center" spacing="extraTight">
                          <Icon source={ReplayMinor} color="inkLighter" />
                          <p>
                            {translate('every')} {item.repeat.repeatAfter}{' '}
                            {item.repeat.repeatMoment}
                          </p>
                        </Stack>
                      </>
                    )}

                    {item.complete_duration && (
                      <>
                        <div style={{ marginTop: 5 }} />
                        <Badge status="success">
                          {translate('duration')}: {item.complete_duration}{' '}
                          {translate('second')}
                        </Badge>
                      </>
                    )}
                  </Stack.Item>
                  <div style={{ textAlign: 'right' }}>
                    <Stack.Item>
                      <Badge>
                        {translate('fileType')}:{' '}
                        {item.fileType === '.xlsx'
                          ? 'Excel'
                          : item.fileType === '.csv'
                          ? 'CSV'
                          : item.fileType === '.zip'
                          ? 'Zip'
                          : null}
                      </Badge>
                      <br />

                      {item.jobStatus === JobStatus.FINISHED && (
                        <Badge status="success">{translate('finished')}</Badge>
                      )}
                      {item.jobStatus === JobStatus.IN_PROGRESS && (
                        <Badge status="info">{translate('inProgress')}</Badge>
                      )}
                      {item.jobStatus === JobStatus.SCHEDULED && (
                        <Badge status="info">{translate('scheduled')}</Badge>
                      )}
                      {item.jobStatus === JobStatus.FINISHED_LIMITED && (
                        <Badge status="warning">
                          {translate('finishedOrLimited')}
                        </Badge>
                      )}
                      {item.jobStatus === JobStatus.CANCELLED && (
                        <Badge status="attention">
                          {translate('cancelled')}
                        </Badge>
                      )}
                      {item.jobStatus === JobStatus.READY_TO_IMPORT && (
                        <Badge>{translate('readyToImport')}</Badge>
                      )}
                      {item.jobStatus === JobStatus.FAILED && (
                        <Badge status="warning">{translate('failed')}</Badge>
                      )}
                      <br />
                      <div style={{ marginTop: 5 }} />

                      {item.fileLink && (
                        <>
                          <Link
                            url={item.fileLink}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {item.fileName}
                          </Link>
                          <br />
                          <div style={{ marginBottom: 5 }} />
                        </>
                      )}
                      {item.importResultFileLink && (
                        <>
                          <Link
                            url={item.importResultFileLink}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {item.importResultFileName}
                          </Link>
                          <br />
                          <div style={{ marginBottom: 5 }} />
                        </>
                      )}

                      {(item.jobStatus === JobStatus.SCHEDULED ||
                        item.jobStatus === JobStatus.READY_TO_IMPORT) && (
                        <>
                          <Button
                            loading={isJobCanceling}
                            onClick={(e) =>
                              jobCancelHandler(
                                e,
                                item.jobType,
                                item.jobQueueId,
                                item._id,
                                item.shopName
                              )
                            }
                            destructive
                          >
                            {translate('cancel')}
                          </Button>
                        </>
                      )}
                    </Stack.Item>
                  </div>
                </Stack>
              </Stack>
            </ResourceItem>
          );
        }}
      />
    </Card>
  );
}
