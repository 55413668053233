import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Provider, TitleBar } from '@shopify/app-bridge-react';
import io from 'socket.io-client';
import {
  Page,
  Banner,
  Card,
  Button,
  Layout,
  FooterHelp,
  Link,
  Stack,
  Spinner,
  Frame,
  Toast,
  ResourceList,
  ResourceItem,
  DisplayText,
  TextStyle,
  TextContainer,
  SkeletonDisplayText,
  SkeletonBodyText,
} from '@shopify/polaris';

import axios from 'axios';

import { injectIntl } from 'react-intl';

import * as JobStatus from '../JobStatus';

// import components
import ImportSection from '../ImportSection';
import Jobs from '../Jobs';
import JobStatusCards from '../JobStatusCards';

import { MainContext } from '../Provider';

import translate from '../../i18n/translate';

function Home(props) {
  const history = useHistory();
  const mainContext = useContext(MainContext);
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const primaryAction = {
    content: props.intl.formatMessage({
      id: 'upgrade_plan',
    }),
    onAction: () => history.push('/plan'),
  };
  const secondaryActions = [
    {
      content: props.intl.formatMessage({
        id: 'settings',
      }),
      onAction: () => history.push('/settings'),
    },
    {
      content: props.intl.formatMessage({
        id: 'scheduled',
      }),
      onAction: () =>
        history.push(`/all-jobs?jobStatus=${JobStatus.SCHEDULED}`),
    },
    {
      content: props.intl.formatMessage({
        id: 'completed',
      }),
      onAction: () => history.push(`/all-jobs?jobStatus=${JobStatus.FINISHED}`),
    },
    {
      content: props.intl.formatMessage({
        id: 'errors',
      }),
      onAction: () => history.push(`/all-jobs?jobStatus=${JobStatus.FAILED}`),
    },
    {
      content: props.intl.formatMessage({
        id: 'allJobs',
      }),
      onAction: () => history.push('/all-jobs'),
    },
  ];

  const fetchData = () => {
    setPageLoading(true);
    axios
      .post('/get_home_page_jobs', {
        shopName: props.config.shopOrigin,
      })
      .then(({ data }) => {
        setPageLoading(false);
        mainContext.setHomePageJobs(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get('/plan_details', {
        params: {
          shopName: props.config.shopOrigin,
        },
      })
      .then(({ data }) => {
        setPageLoading(false);
        mainContext.setPlanPageLoaded(true);
        mainContext.setPlanConfig(data.planConfig);
        mainContext.setShopCurrentPlan(data.shopCurrentPlan);
        mainContext.setShop(data.shop);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.config.shopOrigin]);

  useEffect(() => {
    const socket = io();
    window.scroll(0, 0);
    fetchData();
    socket.on(props.config.shopOrigin, (msg) => {
      if (msg.exportJobDone) {
        fetchData();
      }
    });
    return () => {
      socket.off(props.config.shopOrigin);
    };
  }, []);

  const [
    planChangedBannerDismissState,
    setPlanChangedBannerDismissState,
  ] = useState(true);

  const planChangedBannerDismissHandler = useCallback(() => {
    setPlanChangedBannerDismissState(false);
    axios
      .post('/plan_changed_banner_dismiss', {
        shopNameToken: props.shopNameToken,
      })
      .then(({ data }) => {})
      .catch((err) => {
        console.log(err);
      });
  }, [props.shopNameToken]);

  return (
    <MainContext.Consumer>
      {(context) => (
        <Frame>
          {toast && <Toast content={toast} onDismiss={() => setToast(false)} />}
          {errorToast && (
            <Toast
              error
              content={errorToast}
              onDismiss={() => setErrorToast(false)}
            />
          )}
          <Page>
            <Provider config={props.config}>
              <TitleBar
                title={props.intl.formatMessage({
                  id: 'home',
                })}
                primaryAction={primaryAction}
                secondaryActions={secondaryActions}
              />
            </Provider>
            <br />
            {context?.shop?.planChanged &&
              planChangedBannerDismissState &&
              context?.shopCurrentPlan?.name && (
                <>
                  <Banner
                    title={translate('plan_changed_notification', {
                      planName: context.shopCurrentPlan.name,
                    })}
                    status="success"
                    onDismiss={planChangedBannerDismissHandler}
                  />
                  <br />
                </>
              )}
            {context.shopCurrentPlan.name ? (
              <Banner
                title={translate('current_plan_status_text', {
                  planName: context.shopCurrentPlan.name,
                })}
                action={{
                  content: props.intl.formatMessage({
                    id: 'upgrade_plan',
                  }),
                  onAction: () => history.push('/plan'),
                }}
                status="info"
              >
                <p>{translate('want_more')}</p>
              </Banner>
            ) : (
              <Card sectioned>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText />
                </TextContainer>
              </Card>
            )}

            <br />
            <br />
            <JobStatusCards shopName={props.config.shopOrigin} />
            <br />
            <Card
              title={props.intl.formatMessage({
                id: 'export',
              })}
            >
              <Card.Section>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: 'auto' }}>
                    <p>{translate('export_description')}</p>
                    <br />
                    <Button primary onClick={() => history.push('/new-export')}>
                      {translate('new_export')}
                    </Button>
                  </div>
                  <img
                    style={{ position: 'relative', top: -15 }}
                    src="/assets/export.png"
                    alt={props.intl.formatMessage({
                      id: 'export',
                    })}
                  />
                </div>
              </Card.Section>
            </Card>
            <ImportSection
              setToast={setToast}
              setErrorToast={setErrorToast}
              shopName={props.config.shopOrigin}
            />
            {!context.homePageJobsLoaded && pageLoading ? (
              <>
                <br />
                <Stack distribution="center">
                  <Spinner
                    accessibilityLabel="Spinner example"
                    size="large"
                    color="teal"
                  />
                </Stack>
              </>
            ) : (
              <>
                <br />
                <DisplayText size="medium">
                  {props.intl.formatMessage({
                    id: 'scheduledJobs',
                  })}
                </DisplayText>
                <div style={{ marginBottom: 10 }} />
                <Jobs
                  data={context.homePageJobs.scheduled}
                  title={props.intl.formatMessage({
                    id: 'scheduledJobs',
                  })}
                />
                <div style={{ marginTop: 5 }} />
                <Stack distribution="center">
                  <Button
                    onClick={() =>
                      history.push(`/all-jobs?jobStatus=${JobStatus.SCHEDULED}`)
                    }
                    plain
                  >
                    {translate('viewAllScheduledJobs')}
                  </Button>
                </Stack>
                <br />
                <DisplayText size="medium">
                  {translate('jobsWithErrors')}
                </DisplayText>
                <div style={{ marginBottom: 10 }} />
                <Jobs
                  data={context.homePageJobs.failed}
                  title={props.intl.formatMessage({
                    id: 'errorJobs',
                  })}
                />
                <div style={{ marginTop: 5 }} />
                <Stack distribution="center">
                  <Button
                    onClick={() =>
                      history.push(`/all-jobs?jobStatus=${JobStatus.FAILED}`)
                    }
                    plain
                  >
                    {translate('viewAllJobsWithErrors')}
                  </Button>
                </Stack>
                <br />
                <DisplayText size="medium">
                  {translate('completedJobs')}
                </DisplayText>
                <div style={{ marginBottom: 10 }} />
                <Jobs
                  data={context.homePageJobs.finished}
                  title={props.intl.formatMessage({
                    id: 'finishedJobs',
                  })}
                />
                <div style={{ marginTop: 5 }} />
                <Stack distribution="center">
                  <Button
                    onClick={() =>
                      history.push(`/all-jobs?jobStatus=${JobStatus.FINISHED}`)
                    }
                    plain
                  >
                    {translate('viewAllFinishedJobs')}
                  </Button>
                </Stack>
                <br />

                <div style={{ marginTop: 5 }} />
                <div style={{ borderBottom: '.1rem solid #d3dbe2' }} />
                <Stack distribution="center">
                  <Button onClick={() => history.push('/all-jobs')} plain>
                    {translate('viewAllJobs')}
                  </Button>
                </Stack>

                <br />
              </>
            )}

            <Layout.Section />
            <Layout>
              <Layout.Section>
                <Card
                  title={props.intl.formatMessage({
                    id: 'getHelp',
                  })}
                  sectioned
                >
                  <ResourceList
                    items={[
                      {
                        id: 0,
                        url: 'https://guides.clementlabs.com/tie',
                        name: props.intl.formatMessage({
                          id: 'theTIEAppGuides',
                        }),
                      },
                      {
                        id: 1,
                        url:
                          'https://guides.clementlabs.com/tie#export-from-shopify',
                        name: props.intl.formatMessage({
                          id: 'howToExport',
                        }),
                      },
                      {
                        id: 3,
                        url:
                          'https://guides.clementlabs.com/tie#edit-shopify-data',
                        name: props.intl.formatMessage({
                          id: 'editingYourData',
                        }),
                      },
                      {
                        id: 2,
                        url:
                          'https://guides.clementlabs.com/tie#import-to-shopify',
                        name: props.intl.formatMessage({
                          id: 'howToImport',
                        }),
                      },
                    ]}
                    renderItem={(item) => {
                      const { id, url, name } = item;

                      return (
                        <ResourceItem
                          id={id}
                          // onClick={() => window.open(url, '_blank')}
                          onClick={() => window.open(url, '_blank')}
                        >
                          <h3>
                            <TextStyle variation="strong">{name}</TextStyle>
                          </h3>
                        </ResourceItem>
                      );
                    }}
                  />
                </Card>
              </Layout.Section>

              <Layout.Section>
                <FooterHelp>
                  {translate('needHelp')}{' '}
                  <Link
                    onClick={() => {
                      window.open('mailto:tieapp@clementlabs.com', '_blank');
                    }}
                  >
                    tieapp@clementlabs.com
                  </Link>
                </FooterHelp>
              </Layout.Section>
            </Layout>
          </Page>
        </Frame>
      )}
    </MainContext.Consumer>
  );
}

export default injectIntl(Home);
