/**
 *
 * @param {string} url
 * @returns {{user,password,host,port,path}}
 */

export default (url) => {
  try {
    const parsedResult = {};
    const temp = url.split('://')[1].split(':');
    parsedResult.user = temp[0];
    parsedResult.password = temp[1].split('@')[0];
    parsedResult.host = temp[1].split('@')[1];
    parsedResult.port = temp[2].split('/')[0];
    parsedResult.path = temp[2]
      .split('/')
      .slice(1, temp[2].split('/').length)
      .join('/');

    return parsedResult;
  } catch (err) {
    throw new Error('Invalid URL for FTP/SFTP');
  }
};
